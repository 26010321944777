import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/SectionTitle'
import SingleService from '../../components/Service';

import shapeImg1 from '../../assets/img/service/sv-shape-1.png';
import shapeImg2 from '../../assets/img/service/sv-shape-2.png';

import serviceIconBg1 from '../../assets/img/service/sv-icon-shape-1.png'
import serviceIconBg2 from '../../assets/img/service/sv-icon-shape-2.png'
import serviceIconBg3 from '../../assets/img/service/sv-icon-shape-3.png'
import serviceIconBg4 from '../../assets/img/service/sv-icon-shape-4.png'
import serviceIconBg5 from '../../assets/img/service/sv-icon-shape-5.png'
import serviceIconBg6 from '../../assets/img/service/sv-icon-shape-6.png'
import BrandDesignStrategy from '../../assets/img/service/Brand-Design-Strategy.png';
import CreativeAndContent from '../../assets/img/service/Creative-and-content.png';
import DataInsights from '../../assets/img/service/Data-Insights.png';
import DedicatedResource from '../../assets/img/service/Dedicated-Resource.png';
import DesignandDevelopment from '../../assets/img/service/Design-and-Development.png';
import EmailMarketing from '../../assets/img/service/Email-Marketing.png';
import Paidmedia from '../../assets/img/service/paid-media.png';
import SEO from '../../assets/img/service/SEO.png';
import SocialMediaStrategy from '../../assets/img/service/Social-Media-Strategy.png';
import WhiteLabel from '../../assets/img/service/White-Label.png';
// import Paidmedia from '../../assets/img/service/paid-media.png';

const Service = () => {

    return (
        <div className="service__area service__space service__mlr pt-90 pb-70 p-relative home-page">
            <div className="service__shape-1">
                <img src={shapeImg1} alt="" />
            </div>
            <div className="service__shape-2">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-10 wow animate__fadeInUp" data-wow-duration="1.1s">
                        <SectionTitle
                            sectionClass= "service__section-box text-center pb-35"
                            subTitle= "We’ve Everything to Offer"
                            Title= " We’ve Got the Tools, You’ve Got the Vision– Let’s Build Together"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-1 p-relative fix"
                            titleClass="service__title title-color-1"
                            btnClass= "service__link service__link-color-1"
                            serviceIconBg= {serviceIconBg1}
                            serviceImage= {SocialMediaStrategy}
                            Title= "Social Media Strategy"
                            Description= "Don't have time to do it? We'll help you with post-creation & scheduling. Did it work? We'll develop tailored strategies to focus on your overall page growth. We're a buzz builder and strive to give an authentic voice to your brand."
                            btnText= "Know More"
                            linkPage= "/smm"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-2 p-relative fix"
                            titleClass="service__title title-color-2"
                            btnClass= "service__link service__link-color-2"
                            serviceIconBg= {serviceIconBg2}
                            serviceImage= {CreativeAndContent}
                            Title= "Creative and Content"
                            Description= "From website & blog content and content marketing to overall visual design, we offer everything! Our multi-skilled content production team works closely with you to ensure everything aligns with your vision"
                            btnText= "Know More"
                            linkPage= "/creative-content"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-3 p-relative fix"
                            titleClass="service__title title-color-3"
                            btnClass= "service__link service__link-color-3"
                            serviceIconBg= {serviceIconBg3}
                            serviceImage= {DesignandDevelopment}
                            Title= "Design & Development"
                            Description= "We turn clicks into conversions. From strategy development to wireframe design, content curation, and optimizing user experience, we create SEO-friendly professional websites that convert your business into a brand."
                            btnText= "Know More"
                            linkPage= "/design-development"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-4 p-relative fix"
                            titleClass="service__title title-color-4"
                            btnClass= "service__link service__link-color-4"
                            serviceIconBg= {serviceIconBg4}
                            serviceImage= {SEO}
                            Title= "SEO"
                            Description= "From keywords to conversions– we’ve got your SEO covered. We provide world-class SEO services using White Hat techniques paired with regular tracking and reporting to put your brand in front of the right audience."
                            btnText= "Know More"
                            linkPage= "/seo"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-5 p-relative fix"
                            titleClass="service__title title-color-5"
                            btnClass= "service__link service__link-color-5"
                            serviceIconBg= {serviceIconBg5}
                            serviceImage= {Paidmedia}
                            Title= "Paid Media"
                            Description= "Be in front of those who need you. At Buzz, we craft compelling content and designs for laser-focused ad campaigns that move the needle. Our PPC strategists follow a full-funnel approach ensuring your brand excels across all channels."
                            btnText= "Know More"
                            linkPage= "/paid-media"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-6 p-relative fix"
                            titleClass="service__title title-color-6"
                            btnClass= "service__link service__link-color-6"
                            serviceIconBg= {serviceIconBg6}
                            serviceImage= {BrandDesignStrategy}
                            Title= "Brand Discovery & Strategy"
                            Description= "We cut through the noise with a well-devised brand strategy. Our expertise spans creating collaterals, and brand architecture to deliver human-centric experiences that deeply connect your brand with people’s interests."
                            btnText= "Know More"
                            linkPage= "/contact"
                        />
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-1 p-relative fix"
                            titleClass="service__title title-color-1"
                            btnClass= "service__link service__link-color-1"
                            serviceIconBg= {serviceIconBg1}
                            serviceImage= {DataInsights}
                            Title= "Data and Insights"
                            Description= "We convert data into insights that you didn’t even know you had. Empower your business with our post-campaign evaluations for strategic decision-making and sustained growth. Gain deep insights into what your audiences actually want."
                            btnText= "Know More"
                            linkPage= "/contact"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-2 p-relative fix"
                            titleClass="service__title title-color-2"
                            btnClass= "service__link service__link-color-2"
                            serviceIconBg= {serviceIconBg2}
                            serviceImage= {EmailMarketing}
                            Title= "Email Marketing Automation"
                            Description= "Secure the rapt attention of your subscribers by sending the right message at the right time. At Buzz, we help you with email writing & design, HTML Emailers, setup & schedule, and reporting to enhance the efficiency of your marketing campaigns."
                            btnText= "Know More"
                            linkPage= "/contact"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-3 p-relative fix"
                            titleClass="service__title title-color-3"
                            btnClass= "service__link service__link-color-3"
                            serviceIconBg= {serviceIconBg3}
                            serviceImage= {WhiteLabel}
                            Title= "White Label Services"
                            Description= "We’ll take care of your clients just like you do. Our white label experts handle all your client’s requests for PPC, SEO, or web development ensuring on-time delivery to help you increase referrals and maintain a client retention ratio."
                            btnText= "Know More"
                            linkPage= "/contact"
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-4 p-relative fix"
                            titleClass="service__title title-color-4"
                            btnClass= "service__link service__link-color-4"
                            serviceIconBg= {serviceIconBg4}
                            serviceImage= {DedicatedResource}
                            Title= "Dedicated Resources"
                            Description= "Accomplish your dream project by hiring the most qualified and skilled resources with flexibility and cost-effectiveness. Hire the best talent for SEO, SEM, SMM, Shopify developers, Wordpress developers, PHP developers or UI/UX designs to meet the highest standards, ensuring a superior end-product."
                            btnText= "Know More"
                            linkPage= "/contact"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
import React from 'react';
import ContactTwo from '../../components/Contact/ContactTwo';
import Breadcrumb from '../../components/Breadcrumb';
import CTA from '../home/CtaSection';

import bgImg from '../../assets/img/cta/cta-bg-2.png';

const ContactMain = () => {
	return (
		<main>
			<Breadcrumb
				pageTitle= "Get Started"
			/>

			<ContactTwo />
			
			<CTA />

		</main>
	);
}

export default ContactMain;
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import blogSlice from '../redux/slice/blogSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const rootReducer = combineReducers({ 
  blog: blogSlice
})
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer
})

export const persistor = persistStore(store)
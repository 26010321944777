import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CareerContent from "./CareerContent";
import { Helmet } from "react-helmet";

function Careers() {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Career</title>
          <meta name="description" content="At Buzz Global Marketing , we're committed to growing together, hand in hand with you" />
          <link rel="canonical" href={`${window.location.host}/career`}/>
      </Helmet>
      <Header
        parentMenu="contact"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <CareerContent />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Careers;

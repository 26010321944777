import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const SmmFAQ = (props) => {
    const { itemClass } = props;
    return (
        <div className={itemClass ? itemClass : 'it-custom-accordion'}>
            <Accordion className="accordion" preExpanded={'a'}>
                <AccordionItem className="accordion-items" uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What SMO platform is best for my business?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Well, it's like choosing the perfect influencer for your brand. If you’re networking with business owners, and C-suite executives, LinkedIn and Twitter are your VIP lounge. If you want to build brand awareness through visual storytelling, Instagram, Pinterest, Facebook, and TikTok are the best.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="b">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> How can SMO help in ranking my website?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Typically, more traffic, more shares, more love—it all adds up to better rankings. To improve your website ranking on Google, our experts conduct certain key activities like:</p>
                        <ul className='faq-wrapper'>
                            <li>Micro blogging</li>
                            <li>Profile Maintenance</li>
                            <li>Social Bookmarking</li>
                            <li>Make your content skimmable</li>
                            <li>Optimizing posts for searches</li>
                            <li>Social media integration on the website</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="c">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Is Social Media Optimization (SMO) required for my business?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Absolutely. Yes. And, numbers don’t lie. 54% of social media users use social media to research products and 71% are more likely to purchase products and services based on social media referrals. If you’re not doing SMO, you’re missing out on a massive opportunity to get noticed and go viral.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="d">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What is the cost of SMO service?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>There’s no one-fits-all pricing structure for social media marketing. The cost of SMO service depends on how big you want to go. Whether you want a little boost or full-fledged campaign management across all social platforms, we’ve got options for every budget.</p>
                        <p>P.S.: Different companies offer different pricing structures such as hourly basis, monthly retainers, project-based, performance-based, and package-based. Choose the best structure that fits your business model.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="e">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What are the tools used for SMO?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Some of the commonly used tools for SMO are:</p>
                        <ul className='faq-wrapper'>
                            <li>Hootsuite</li>
                            <li>Buffer</li>
                            <li>Sprinklr</li>
                            <li>Agora Pulse</li>
                            <li>Sprout Social</li>
                            <li>Sendible</li>
                            <li>SocialPilot and more..</li>
                        </ul>
                        <p>At Buzz, we majorly use Sprout Social & Buffer to automate scheduling, analytics, and insights, optimize post-publishing, and content curation, and manage overall social presence.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="f">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Would I still need SMO if I had SEO done already?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Yes, it’s important to do SMO even if you have SEO done already. SEO improves your ranking in SERPs and is crucial for long-term success, while SMO optimizes your social presence and creates a brand identity through social media channels. Both work hand in hand to improve your visibility online.</p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default SmmFAQ;

import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
// import bgImg from "../../assets/img/cta/cta-bg-2.png";
// import CTA from "../../components/CTA";
import CTA from '../home/CtaSection';
// import Faq from "../../components/Faq";

import { Link } from 'react-router-dom';

// import BrandDesignStrategy from "../../assets/img/service/Brand-Design-Strategy.png";
// import serviceImg2 from "../../assets/img/service/service-details-2.jpg";
import SingleServiceFour from "../../components/Service/SingleServiceFour";

// import shapeImg1 from "../../assets/img/service/sv-item-shape-1.png";
// import shapeImg2 from "../../assets/img/service/sv-item-shape-2.png";
// import shapeImg3 from "../../assets/img/service/sv-item-shape-3.png";
// import shapeImg4 from "../../assets/img/service/sv-item-shape-4.png";
// import shapeImg5 from "../../assets/img/service/sv-item-shape-5.png";
// import shapeImg6 from "../../assets/img/service/sv-item-shape-6.png";

import shapeIcon1 from "../../assets/img/service/sv-icon-shape-7.png";
import shapeIcon2 from "../../assets/img/service/sv-icon-shape-8.png";
import shapeIcon3 from "../../assets/img/service/sv-icon-shape-9.png";
import shapeIcon4 from "../../assets/img/service/sv-icon-shape-10.png";
import ourCompany from '../../assets/img/company/our-company.png';
// import social from "../../assets/img/service/social.png";
// import shapeIcon6 from "../../assets/img/service/sv-icon-shape-12.png";


import eCom from '../../assets/img/design/Design-Services/ecom.png';
import mobileDev from '../../assets/img/design/Design-Services/mobile-dev.png';
import uI from '../../assets/img/design/Design-Services/UI.png';
import webDesign from '../../assets/img/design/Design-Services/web-design.png';

import deploy from '../../assets/img/design/Strategy/deployment.png';
import designdev from '../../assets/img/design/Strategy/design-dev.png';
import info from '../../assets/img/design/Strategy/information-archi.png';
import quality from '../../assets/img/design/Strategy/quality.png';
import strategy from '../../assets/img/design/Strategy/strategy.png';
import support from '../../assets/img/design/Strategy/support.png';

import angular from '../../assets/img/design/languages/angular.png';
import drupal from '../../assets/img/design/languages/drupal.png';
import joomla from '../../assets/img/design/languages/joomla.webp';
import laravel from '../../assets/img/design/languages/laravel.png';
import mobileapp from '../../assets/img/design/languages/mobile-app.png';
import mongo from '../../assets/img/design/languages/mongo.png';
import nodejs from '../../assets/img/design/languages/nodejs.png';
import payment from '../../assets/img/design/languages/payment.png';
import php from '../../assets/img/design/languages/php.png';
import react from '../../assets/img/design/languages/react.webp';
import restapi from '../../assets/img/design/languages/restapi.png';
import shopify from '../../assets/img/design/languages/shopify.png';
import shopifyplus from '../../assets/img/design/languages/shopifyplus.png';
import wordpress from '../../assets/img/design/languages/wordpress.png';
import SeoFaq from "../../components/Faq/Seo-Faq";

function SeoServices() {
  return (
    <main>
      <Breadcrumb pageTitle="SEO" />
      <div className="service-details__area service-details__plr mt-110 mb-30">
        <div className="container-fluid">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
                  <div className="service-details__section-box">
                    <h4 className="section-title text-center">
                    Get Found FIRST! Skyrocket Your Staggering Traffic Like Never Before
                    </h4>
                    <p className="text-center">
                      {" "}
                      Future proof your business with our AI-powered SEO services to boost visibility, find and attract the right audience and stay ahead of the game, organically!
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
    

          <div className="company__area pt-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <h4 className="section-about-text char-anim">Best Organic Search Marketing Company</h4>
                        <div className="company__left-img text-center text-xl-start">
                            <img src={ourCompany} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="company__section-box">
                            
                            <p className="char-anim-2">
                              Buzz Global is one of the leading SEO Companies that convert data into actionable insights with tried and true SEO techniques.
                              </p>
                              <p className="char-anim-2">
                              We specialize in helping businesses get noticed and improve your SERPs with our tailored SEO strategies for sustainable growth. Our team of professional SEO experts goes beyond gimmicks and follows a data-driven approach for lasting success.
                              </p>
                              <p className="char-anim-2">
                              From conducting detailed keyword research, SEO audit, on-page, off-page, or technical SEO, to developing and promoting high-quality content across digital channels, we offer a full suite of SEO services to take your business to newer heights.
                              </p>
                              <p className="char-anim-2">
                              So, if you’re new to the online business, or are your sales going down, we invest time to patiently understand your business, identify your target market audience, and conduct competitor analysis to build a comprehensive strategy designed to achieve long-term growth.
                              </p>
                              <p className="char-anim-2">
                              Buzz Global is your trusted partner. We strive to take your business from invisible to invincible in search results.
                              </p>
                              <h3 className="section-title pb-10 char-anim">So, Stop Struggling.<br/>Start Dominating.<br/>It’s time to find and win your audience!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <div className="row" style={{ marginTop: "100px" }}>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                        Our SEO Services
                      </h4>
                      <h3 className="section-title title-anim">
                      You’re looking for the GROWTH. We’ve got the SERVICES you need
                      </h3>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage= {uI}
                      serviceIconBg={shapeIcon1}
                      Title="Link Building Services"
                      serviceDescription="Want to boost your authority and own the web? At Buzz, we help you with white-hat link-building strategies that connect your site to high-quality backlinks. From PR, and content marketing to outreach, we improve your search rankings and grow your digital presence to help your brand get talked on online."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage= {webDesign}
                      serviceIconBg={shapeIcon2}
                      Title="Local SEO Services"
                      serviceDescription="Want to be the go-to in your neighborhood? It’s time to invest in our local SEO services to get found and bring customers to your door. Our local SEO experts will help you with Google and Bing business profile optimization, local directories and map results, and local link building campaigns to make a difference in the local market with tailored strategies."
                    />
                  </div>
                  
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={eCom}
                      serviceIconBg={shapeIcon4}
                      Title="eCommerce SEO Services"
                      serviceDescription="Turn window shoppers into loyal customers with our strategic eCommerce SEO services. With our in-house team of eCommerce SEO experts, we help you with eCommerce SEO audit, keyword research and mapping, category page categorization, URL structure, website structure optimization, and overall landing page optimization."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={mobileDev}
                      serviceIconBg={shapeIcon3}
                      Title="GA consulting"
                      serviceDescription="We wear multiple hats. As your Google Analytics consultant, we will set up a Google Analytics account for your website, track your business objectives and KPIs, conduct cross platform analysis, launch marketing campaigns, GA migration, and overall reporting and analysis for informed decision making. Let the numbers guide your growth!"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                        SEO Process
                      </h4>
                      <h3 className="section-title title-anim">
                        The Process We Follow
                      </h3>
                      <p className="char-anim-2">Being the well-established SEO Agency in the USA, our ultimate goal is to deliver the best possible results, nothing less than that.</p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage={strategy}
                      serviceIconBg={shapeIcon1}
                      Title="Research & Strategy"
                      serviceDescription="We take time to patiently understand your business and dive deep into your offerings, overall industry, and competitive landscape. Our main objective is to determine your USP and prepare tailored SEO strategies that align perfectly with your specific business objectives."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage={info}
                      serviceIconBg={shapeIcon2}
                      Title="Target Audience"
                      serviceDescription="Do you really know who is searching for you? What keeps them up at night? Why are they googling your business at 3 A.M? To deliver this insightful information, we understand who all are your target audience. Our team of analysts audits your social following, takes notes on your competition, reviews your marketing performance metrics, and analyzes pain points and search and buying intent to know your target audience."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={designdev}
                      serviceIconBg={shapeIcon3}
                      Title="Competitive Analysis"
                      serviceDescription="Want to leave your competitors in dust? Our SEO experts with decades of experience gain a panoramic view of your competitors and conduct SWOT analysis. We get to know them better than they know themselves. With this intel, we help you identify untapped opportunities for your business, capitalize on areas, and turn their missteps into your success."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={quality}
                      serviceIconBg={shapeIcon4}
                      Title="Current SEO Audit"
                      serviceDescription="We review your online assets and test your performance by conducting a thorough SEO audit. Our SEO auditors prepare a pretty well-rounded checklist that incorporates technical, off-page, and on-page SEO. This includes identifying link-building opportunities, optimizing metadata, finding and fixing thin and duplicate content, scanning for site errors, finding potential architectural improvements, and switching from HTTP to HTTPS to test the health of your current website."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      serviceImage={support}
                      serviceIconBg={shapeIcon1}
                      Title="Keyword Research"
                      serviceDescription="Our team performs extensive keyword research considering your business offerings and target audiences to boost the visibility of your website. We begin by identifying keyword research, including the combination and variation of words and phrases, and then narrow down that list later after selecting the valuable keywords for your business."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg2}
                      serviceImage={deploy}
                      serviceIconBg={shapeIcon2}
                      Title="Content Strategy"
                      serviceDescription="Once we’ve defined your audience and keywords, we create new pages and add relevant content to your website. From blog posts to landing pages, our experienced SEO writers prepare a checklist and optimize the content – making content AI-free and plagiarism-free, adding CTAs, incorporating authoritative statistics, and adding real-life case studies to improve your SERPs ranking and drive traffic to your website."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage={info}
                      serviceIconBg={shapeIcon2}
                      Title="Site Architecture & Technical SEO"
                      serviceDescription="Apart from content, website technical flaws can seriously impact your organic search performance. Our technical SEO team meticulously audits and optimizes your site architecture and looks after website indexation. We even conduct log file analysis and crawl optimization to identify crawler issues, resolve errors, and improve crawl efficiency to provide the best user experience possible."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      serviceImage={support}
                      serviceIconBg={shapeIcon1}
                      Title="Reporting & Maintenance"
                      serviceDescription="Our job doesn’t end here. We keep our clients in the loop. Our in-house SEO team prepares detailed reports for website performance and SEO progress, stating traffic, rankings, & conversions. We don’t stop there. SEO is an ongoing process and therefore we strive towards maintaining website authority, and local visibility, refreshing the content & adapting to algorithm changes to keep you on top."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h3 className="section-title title-anim">
                      Why SEO is STILL So Important?
                      </h3>
                      <p className="char-anim-2">
                      From small stores to local domination and global stores, everyone wants to be visible. SEO is your ticket to rank on SERPs, making sure customers can find you, not your competitors. It’s the most organic way to stay noticed. Because being invisible online is just so last season!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      // serviceImage={wordpress}
                      // serviceIconBg={shapeIcon1}
                      Title="SEO is Your VIP Pass to Traffic"
                      serviceDescription="Unlike traditional marketing, SEO is hyper-targeted and customer-centric. Instead of paying for a billboard, it helps you drive high-quality traffic to your website. Because, through SEO you’re reaching your right audience at a prime time. Top of that, they’re looking for you, so you’ve their full attention."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      // serviceImage={react}
                      // serviceIconBg={shapeIcon2}
                      Title="Seals the Trust and Credibility"
                      serviceDescription="Everyone trusts Google like it’s the ultimate truth-teller. Why? Because ranking on the first page of Google builds trust and credibility of your website for prospective customers. If you want to reap the benefits of SEO, optimize your content and overall website for better performance and smooth mobile usability. Altogether, this will build the credibility of your business."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      // serviceImage={nodejs}
                      // serviceIconBg={shapeIcon3}
                      Title="Improves User Experience & Engagement"
                      serviceDescription="User experience is a critical component of SEO and therefore, it’s equally important to understand the ease and flexibility of using the application from the user's perspective. Any sort of mobile incompatibility or a barrage of pop-ups will leave users within seconds, thereby increasing the bounce rates and lowering the ranking on SERPs."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      // serviceImage={angular}
                      // serviceIconBg={shapeIcon4}
                      Title="Budget Friendly"
                      serviceDescription="Unlike ads that keep your money, SEO is purely an organic and long-term strategy with minimal ongoing costs. You can hire experts for professional SEO solutions who can implement thoughtful strategies from content creation to on-page optimization and help you promote your website 24/7 keeping everything under budget."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      // serviceImage={drupal}
                      // serviceIconBg={shapeIcon1}
                      Title="Measures Success with real numbers"
                      serviceDescription="SEO lets you make strategic decisions for your business by giving you clear data. You can measure some of the key-note numbers like bounce rate, dwell time, website traffic, impressions, no of clicks, and conversions to determine if your strategy is working well."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 mb-30">
              <SeoFaq />
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </main>
  );
}

export default SeoServices;

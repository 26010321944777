import React from 'react';
import FaqPage from '../../components/Faq/FaqPage';

const FaqMain = () => {
	return (
		<main>
     <div className="faq__area faq__plr">
      <div className="container-fluid">
        <FaqPage />
      </div>
     </div>
   </main>
	);
}

export default FaqMain;
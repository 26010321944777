import React from 'react';
import TestimonialMain from './TestimonialMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';


const Testimonial = () => {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Testimonial</title>
          <meta name="description" content="Service Construct deals physical damage with his basic attack in the match" />
          <link rel="canonical" href={`${window.location.host}/testimonial`}/>
      </Helmet> 
      <Header
        parentMenu="page"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <TestimonialMain />
          <Footer />
        </div>
      </div>
          
    </>
  );
}

export default Testimonial;

import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/img/about/about-1.png';
import shapeImg1 from '../../assets/img/about/about-shape-1.png';
import shapeImg2 from '../../assets/img/about/about-shape-2.png';
import aboutShape from '../../assets/img/about/about-icon.jpeg'

const About = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="about__area pb-100 p-relative">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='PO_fBTkoznc' onClose={() => { openModal(); }} />
            <div className="about__shape-1 d-none d-md-block">
                <img src={shapeImg1} alt="" />
            </div>
            <div className="about__shape-2 d-none d-xl-block">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="container">
                <div className="row align-items-center mb-40">
                    <div className="col-xl-5 col-lg-5 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        
                        <h4 className="section-about-text char-anim">Delivering Excellence with a Personal Touch</h4>
                        <div className="about__img">
                            <img src={aboutShape} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="about__section-box pb-10">
                            <h3 className="section-title char-anim">Setting Trends for 360 Degree Digital Marketing Solution Globally</h3>
                        </div>
                        {/* <div className="about__section-box pb-10">
                            <h5 className="char-anim">Setting Trends for 360 Degree Digital Marketing Solution Globally</h5>
                        </div> */}
                        <div className="about__content">
                            <p className="char-anim-2">Buzz Global is a trusted agency that offers impeccable 360 Degree Marketing Solutions. We help companies and institutions attract and retain customers by using industry-proven online marketing tools, building a highly responsive website, running campaigns, and extensive social media management.</p>
                        </div>
                        <div className="about__content">
                            <p className="char-anim-2">Our team of pragmatic and experienced industry experts patiently understand your brand’s core values, and work closely to formulate an end-to-end 360-degree marketing strategy. Starting from website design, development, and maintenance to investing in SEO and campaign management, we make sure to take care of your complete marketing requirement with on-point content and engaging visuals.
                            </p>
                        </div>
                        <div className="about__content">
                            <p className="char-anim-2">With a result-driven approach, we work as a full-service partner to serve your business, anytime.
                            </p>
                        </div>
                        <div className="job-details__list">
                            <ul>
                            <li>Timely & Accurate Service</li>
                            <li>Domain Expertise</li>
                            <li>Transparent practices</li>
                            <li>Flexible Engagement Model</li>
                            </ul>
                        </div>
                        <div className="cta__btn wow animate__fadeInUp serviceDetails-button hire-us" data-wow-duration="1.1s">
                            <a href="/contact" className="white-btn tp-btn-hover alt-color callUS-wrapper"><span>Hire us now!</span></a>
                            Let’s amplify your customer base today!
                        </div>
                        {/* <div className="row gx-0">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 about__border-right">
                                <div className="about__contact-box d-flex align-items-center">
                                    <div className="about__contact-icon">
                                        <span><i className="fas fa-phone-alt"></i></span>
                                    </div>
                                    <div className="about__contact-number">
                                        <a href="tel:+955426535255">+955426535255</a>
                                        <a href="tel:+210154444564">+210154444564</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div
                                    className="about__video d-flex justify-content-md-center justify-content-start align-items-center">
                                    <div className="about__video-icon">
                                        <a className="popup-video" onClick={() => { openModal(); }} href="#"><i className="fas fa-play"></i></a>
                                    </div>
                                    <div className="about__video-text">
                                        <Link to="#" onClick={() => { openModal(); }} >Watch Video</Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
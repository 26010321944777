import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const CreativeContentFAQ = (props) => {
    const { itemClass } = props;
    return (
        <div className={itemClass ? itemClass : 'it-custom-accordion'}>
            <Accordion className="accordion" preExpanded={'a'}>
                <AccordionItem className="accordion-items" uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Why a good content strategy is important?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>A good content strategy guides you on where to go, keeps you on the right path, and builds a strong relationship between your brand and your target audience. It’s more of data-driven strategy building and less of guesswork that helps you hit your target at the right time, despite being in a competitive landscape.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="b">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What is in a content strategy?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>A content strategy is like a recipe for success. It includes:</p>
                        <ul className='faq-wrapper'>
                            <li>Selection of the right ingredients makes your recipe a hit (audience and goals)</li>
                            <li>Planning & finalizing the best dish to serve to your audience (content themes and formats)</li>
                            <li>Figuring out where to serve (distribution channels)</li>
                            <li>A pinch of hospitality (tracking KPIs) keeps everything just right</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="c">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Do you use AI for content creation?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>We don’t believe in shortcuts here! Our entire team of content writers, copywriters, and content marketers are specialized and stay abreast with the latest trends to craft content with precision. We may use AI just for inspiration but we never rely on it for the actual writing.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="d">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> How Do I Measure the ROI of My Content Strategy?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>To measure the ROI of your content strategy, apply this formula - (Return - Investment ÷ Investment) x 100 = ROI.</p>
                        <p>Additionally, to track your KPIs, consider:</p>
                        <ul className='faq-wrapper'>
                            <li>Website traffic</li>
                            <li>Conversion rates</li>
                            <li>Engagement metrics – likes, shares, and comments</li>
                        </ul>
                        <p>To monitor SEO improvements, using Google Analytics, track data points, rankings, and visibility. Compare ROI against your goals to see how well your content has performed.</p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default CreativeContentFAQ;

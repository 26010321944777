import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu } = props;

    const location = useLocation();

    const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [service, setService] = useState(false)
	const [page, setPage] = useState(false)
	const [blog, setBlog] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setService(false)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setService(false)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'service') {
			setHome(false)
			setAbout(false)
			setService(!service)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'page') {
			setHome(false)
			setAbout(false)
			setService(false)
			setPage(!page)
			setBlog(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setService(false)
			setPage(false)
			setBlog(!blog)
		}
	};

    return (
        <>
            <li className={parentMenu === 'home' ? 'active' : ''}><Link to="/">Home</Link></li>
            <li className={location.pathname === '/about-us' ? 'active' : ''}><Link to="/about-us">About</Link></li>
            <li className={parentMenu === 'services' ? 'active' : ''}><Link to="/services">Services</Link></li>
            {/* <li className={location.pathname === "/case-studies" ? "active" : ""}><Link to="/case-studies">Case Studies</Link></li>
            <li className={location.pathname === "/testimonial" ? "active" : ""}><Link to="/testimonial">Testimonials</Link></li> */}
            {/* <li className={parentMenu === 'blog' ? 'active' : ''}><Link to="/blog">Blog</Link></li> */}
            {/* <li className={location.pathname === "/career" ? "active" : ""}><Link to="/career">Careers</Link></li> */}
            <li className={location.pathname === "/contact" ? "active" : ""}><Link to="/contact">Contact</Link></li>
        </>
    );
}

export default MenuItems;
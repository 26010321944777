import React from 'react';
import {Helmet} from "react-helmet";
import BlogMain from './BlogMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Blog = () => {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Blog Page</title>
          <meta name="description" content="Service Construct deals physical damage with his basic attack in the match" />
          <link rel="canonical" href={`${window.location.host}/blog`}/>
      </Helmet>
      <Header 
        parentMenu="blog"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <BlogMain />
          <Footer />
        </div>
      </div>
          
    </>
  );
}

export default Blog;

import React from 'react';
// import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// import companyImg from '../../assets/img/company/company-1.png';
// import tabImg1 from '../../assets/img/company/company-tab-1.png';
// import tabImg2 from '../../assets/img/company/company-tab-1.png';
// import tabImg3 from '../../assets/img/company/company-tab-1.png';
import ourCompany from '../../assets/img/company/our-company.png';


const Company = () => {
    let tab1 = "Our Mission",
        tab2 = "Team Support",
        tab3 = "Projects Job"
        const tabStyle = 'nav nav-tab';

    return (
        <div className="company__area pt-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <h4 className="section-about-text char-anim">Know Us Better, Closer</h4>
                        <div className="company__left-img text-center text-xl-start">
                            <img src={ourCompany} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="company__section-box">
                            
                            <h3 className="section-title pb-10 char-anim">Bringing Together,<br/>Creative | Data | Technology</h3>
                            <p className="char-anim-2"> Buzz Global Marketing offers end-to-end 360-degree marketing solutions with a confluence of
                            innovation, technology, and creativity.</p>
                            <p className="char-anim-2">Whether you need PPC, SEO, SMO, Blogging, or assistance in developing cross-channel strategies to
                            engage and convert, our experts, backed by decades of industry experience, are here to assist.</p>
                            <p className="char-anim-2">With our suite of online marketing services, we help your business generate not only more brand
                            awareness but also revenue.</p>
                        </div>
                        <Tabs> 
                            <div className="company__service-tab">
                                <TabList className={tabStyle}>
                                    <Tab><button className="btn-color-1"><span>{tab1}</span></button></Tab>
                                    <Tab><button className="btn-color-2"><span>{tab2}</span></button></Tab>
                                    <Tab><button className="btn-color-3"><span>{tab3}</span></button></Tab>
                                </TabList>
                                <div className="tab-content" id="myTabContent">
                                    <TabPanel>
                                        <div className="company__tab-content-wrapper d-flex align-items-center">
                                            <p>To be the people’s choice full-service marketing agency across an international frontier and other
 extensive global spaces that deliver impactful solutions with cutting-edge technology to attain nothing
 but customer satisfaction.</p>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="company__tab-content-wrapper d-flex align-items-center">
                                            <p> To empower brands by combining creative excellence, data intelligence, and advanced technology with
                                            custom-made solutions to achieve measurable success for our clients.</p>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="company__tab-content-wrapper d-flex align-items-center">
                                            <p> With years of expertise and decades of experience, our team delivers services with utmost precision and
 attention to detail. We respect your urgency and aim to get you to market quicker without cutting
 corners. Got a question? Need help? We’re here for you, anytime, anywhere.</p>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                        {/* <div className="company__button">
                            <Link to="#" className="main-btn tp-btn-hover alt-color">
                                <span>Read More</span>
                                <b></b>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Company;
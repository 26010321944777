import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CaseStudiesDetails from './CaseStudiesDetails';


function CaseStudiesDetailsMain() {
    return (
        <>
          <Header
            parentMenu="about"
            headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
          />
          <div id="smooth-wrapper">
            <div id="smooth-content">
              <CaseStudiesDetails />
              <Footer />
            </div>
          </div>
              
        </>
      )
}

export default CaseStudiesDetailsMain
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';


const FaqPage = (props) => {
    const { itemClass } = props;
	return(
        <div className={itemClass ? itemClass : 'it-custom-accordion'}>
            <Accordion className="accordion" preExpanded={'a'}>
                <AccordionItem className="accordion-items" uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What Is 360 Digital Marketing?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Digital Marketing is typically a holistic approach to meeting your customers wherever they are online. It’s more about developing all-encompassing strategies that engage, attract, and retain your target audiences across multiple touch points. From influencer collaborations to AI-driven chatbots, personalized ads, and data-driven content, it brings the business closer and more accessible to the right segment of audiences.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="b">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> How long does it take to see results from digital marketing efforts?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Digital Marketing is a sprint and not a marathon. So, the timeline to see the results depends on various
                        factors</p>
                        <ul className='faq-wrapper'>
                            <li>PPC campaigns are faster. You can expect results within 3 to 6 months, as they put you directly in
                            front of your target audience.</li>
                            <li>Social media and content marketing may start showing engagement within a few weeks, but building a loyal audience takes time and consistent effort.</li>
                            <li>SEO typically takes 6 to 8 months before you notice a significant growth in traffic and rankings. It is a long-term investment that pays off gradually</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="c">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> How do I get started with digital marketing for my business?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>To get started with digital marketing for your business, consider these steps</p>
                        <ul className='faq-wrapper'>
                            <li><b>Define your goals and objectives:</b> Ask yourself- what do you want to achieve? Traffic, Ranking, Sales, or Brand Awareness. Based on that, your strategy will be prepared</li>
                            <li><b>Know Your Audience:</b> Ask- Who your ideal customers are? Who can be your potential customers?</li>
                            <li><b>Choose your Channels:</b> Select the right mix of channels like- SEO, Social media, content
                            marketing, or PPC.</li>
                            <li><b>Create a Plan:</b> Based on the above, outline your strategy for content and decide on how often
                            you'll post.</li>
                            <li><b>Seek Expert Help:</b> You can seek assistance from a professional digital marketing agency to
                            accelerate your strategic initiatives.</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
            </Accordion>
        </div> 
	)
}

export default FaqPage
import React from 'react';
import { Link } from 'react-router-dom';

import Shape from '../../assets/img/service/sv-item-shape-1.png'
import Image from '../../assets/img/service/sv-icon-shape-1.png'

const SingleServiceFour = (props) => {
    const { itemClass, titleClass, shapeImg, serviceIconBg, Title, Description, serviceImage, btnURL, btnClass, serviceDescription } = props;
    return(
        <div className={itemClass ? itemClass : 'service__item service__item-bg p-relative fix'}>
            {shapeImg || Shape ? (
                <div className="service__shape-1">
                    <img src={shapeImg ? shapeImg : Shape} alt="" />
                </div>
            ) : null}
            
            <div className="service__icon-wrapper pb-25 p-relative">
                {serviceIconBg || Image ? (
                    <div className="service__icon-bg">
                        <img src={serviceIconBg ? serviceIconBg : Image} alt="" />
                    </div>
                ) : null}

                {serviceImage ? (
                    <div className="service__inner-icon inner-hight">
                        <img src={serviceImage} alt='Service' loading='lazy' />
                    </div>
                ) : null}
            </div>

            <div className="service__content">
                <h4 className={titleClass ? titleClass : 'service__title service__title-color-1'}>
                    {Title ? Title : 'Get Latest Updates'}
                </h4>
                <p className="text-white">{serviceDescription}</p>
            </div>

            {/* <div className={btnClass ? btnClass : 'service__link service__link-color-1'}>
                <Link to={`/${btnURL ? btnURL : 'service-details'}`}>
                    {btnText ? btnText : 'Read More'}<i className="fal fa-arrow-right"></i>
                </Link>
            </div> */}
        </div>
    )
}

export default SingleServiceFour;

import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
// import bgImg from "../../assets/img/cta/cta-bg-2.png";
// import CTA from "../../components/CTA";
import CTA from '../home/CtaSection';
// import Faq from "../../components/Faq";

import { Link } from 'react-router-dom';

// import BrandDesignStrategy from "../../assets/img/service/Brand-Design-Strategy.png";
// import serviceImg2 from "../../assets/img/service/service-details-2.jpg";
import SingleServiceFour from "../../components/Service/SingleServiceFour";

// import shapeImg1 from "../../assets/img/service/sv-item-shape-1.png";
// import shapeImg2 from "../../assets/img/service/sv-item-shape-2.png";
// import shapeImg3 from "../../assets/img/service/sv-item-shape-3.png";
// import shapeImg4 from "../../assets/img/service/sv-item-shape-4.png";
// import shapeImg5 from "../../assets/img/service/sv-item-shape-5.png";
// import shapeImg6 from "../../assets/img/service/sv-item-shape-6.png";

import shapeIcon1 from "../../assets/img/service/sv-icon-shape-7.png";
import shapeIcon2 from "../../assets/img/service/sv-icon-shape-8.png";
import shapeIcon3 from "../../assets/img/service/sv-icon-shape-9.png";
import shapeIcon4 from "../../assets/img/service/sv-icon-shape-10.png";
import ourCompany from '../../assets/img/company/our-company.png';
// import social from "../../assets/img/service/social.png";
// import shapeIcon6 from "../../assets/img/service/sv-icon-shape-12.png";


import eCom from '../../assets/img/design/Design-Services/ecom.png';
import mobileDev from '../../assets/img/design/Design-Services/mobile-dev.png';
import uI from '../../assets/img/design/Design-Services/UI.png';
import webDesign from '../../assets/img/design/Design-Services/web-design.png';

import deploy from '../../assets/img/design/Strategy/deployment.png';
import designdev from '../../assets/img/design/Strategy/design-dev.png';
import info from '../../assets/img/design/Strategy/information-archi.png';
import quality from '../../assets/img/design/Strategy/quality.png';
import strategy from '../../assets/img/design/Strategy/strategy.png';
import support from '../../assets/img/design/Strategy/support.png';

import facebook from '../../assets/img/social-icons/facebook.webp';
import instagram from '../../assets/img/social-icons/instagram.webp';
import linkedin from '../../assets/img/social-icons/linkedin.webp';
import pinterest from '../../assets/img/social-icons/pinterest.webp';
import tiktok from '../../assets/img/social-icons/tiktok.webp';
import xthread from '../../assets/img/social-icons/x.webp';
import youtube from '../../assets/img/social-icons/youtube.webp';
import SmmFAQ from "../../components/Faq/smm-Faq";

function smmServices() {
    return (
        <main>
            <Breadcrumb pageTitle="SMM" />
            <div className="service-details__area service-details__plr mt-110 mb-30">
                <div className="container-fluid">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <div className="service-details__section-box">
                                        <h4 className="section-title text-center">
                                            Let’s Make Your Brand the Talk of the Feed!
                                        </h4>
                                        <p className="text-center">
                                            {" "}
                                            Want to dominate on social media? With our in-house social experts, we craft storytelling content that makes viewers stop scrolling and start paying attention.
                                        </p>
                                        <div className="button-wrapper text-center">
                                        <div className="cta__btn wow animate__fadeInUp serviceDetails-button" data-wow-duration="1.1s">
                                            <a href="tel:025461556695" className="white-btn tp-btn-hover alt-color callUS-wrapper"><span>Call us NOW!</span><b></b>
                                            </a>
                                        </div>
                                    </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="company__area pt-50">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-6 wow animate__fadeInLeft" data-wow-duration="1.1s">
                                    <h4 className="section-about-text char-anim">Best Social Media Marketing Agency</h4>
                                    <div className="company__left-img text-center text-xl-start">
                                        <img src={ourCompany} alt="" />
                                    </div>
                                </div>
                                <div className="col-xl-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                                    <div className="company__section-box">
                                        <h3 className="section-title title-anim">
                                            A glimpse of your <br /> SMO agency
                                        </h3>
                                        <p className="char-anim-2">
                                            We are a leading full service marketing Agency in the USA that takes brand awareness seriously. We have a team of certified SMO experts who plan, strategize, and execute social campaigns thus turning cold prospects into sale-ready advocates.
                                        </p>
                                        <p className="char-anim-2">
                                            Whether you’re looking for a skillful implementation of social campaigns or want an expert team to manage your social platforms fully, we’re here to help. Our team has hands-on experience in understanding your business offerings and target audience and delivering tailored marketing strategies to get the most out of each social platform.
                                        </p>
                                        <p className="char-anim-2">
                                            From conducting social audits to trending copywriting and overall profile optimization, we work fluidly with Twitter, Instagram, TikTok, Facebook, Pinterest, and LinkedIn to achieve holistic growth for our clients.
                                        </p>
                                        <p className="char-anim-2">
                                            At Buzz Global, we strive to turn followers into a thriving community by combining strategy, engaging content, scroll-stopping designs, and tailored targeting.
                                        </p>
                                        <p className="char-anim-2">
                                            SMO is an investment that reaps the heap in return. Partner with the best SMO Agency to convert your likes into a loyal community – without breaking the bank.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "100px" }}>
                        <div className="service__area service__plr pb-70 p-relative">
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div
                                        className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <div className="service__section-box text-center pb-35">
                                            <h4 className="section-subtitle section-white-bg title-anim">
                                                SMO Process
                                            </h4>
                                            <h3 className="section-title title-anim">
                                                Process we follow to Optimize your Business Presence
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-1"
                                            btnClass="service__link service__link-color-1"
                                            // shapeImg={shapeImg1}
                                            serviceImage={uI}
                                            serviceIconBg={shapeIcon1}
                                            Title="Industry Understanding"
                                            serviceDescription="Before diving into any social media strategy, we analyze the dynamics and trends of your industry landscape. This will help us map out how to satisfy customer needs, behavior, and preferences, and identify trends that drive an industry. Based on that, we’ll grasp your market position, understand your targeted audience, and ensure your social presence in alignment with your business objective."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-2"
                                            btnClass="service__link service__link-color-2"
                                            // shapeImg={shapeImg2}
                                            serviceImage={webDesign}
                                            serviceIconBg={shapeIcon2}
                                            Title="Competitor Analysis"
                                            serviceDescription="To stay ahead of the curve, our team of experts conducts social audits to examine what your competitors are doing on social media. By conducting a SWOT analysis, we discover the gaps and opportunities that your business can leverage to get a competitive edge. We will use this intelligent data to formulate a well-devised social media strategy for your business."
                                        />
                                    </div>

                                    <div
                                        className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-4"
                                            btnClass="service__link service__link-color-4"
                                            // shapeImg={shapeImg4}
                                            serviceImage={eCom}
                                            serviceIconBg={shapeIcon4}
                                            Title="Social Media Strategy Building"
                                            serviceDescription="With insights from our research, our certified social media professionals develop a comprehensive social media strategy with defined goals. This includes selecting the right social media platforms for your audience and content, developing an engagement strategy, creating a posting formula, and planning on promotional, nonpromotional, and premium content that gives voice to your brand."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-3"
                                            btnClass="service__link service__link-color-3"
                                            // shapeImg={shapeImg3}
                                            serviceImage={uI}
                                            serviceIconBg={shapeIcon1}
                                            Title="Profile Optimization"
                                            serviceDescription="First impression matters, ALWAYS! To put the strategy into action, we optimize your social media profiles to ensure they’re clear, visually appealing, and reflect your brand identity. From optimizing the profile picture to bio description and keyword usage to using relevant hashtags and CTAs, we look after your overall brand consistency."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-3"
                                            btnClass="service__link service__link-color-3"
                                            // shapeImg={shapeImg3}
                                            serviceImage={webDesign}
                                            serviceIconBg={shapeIcon2}
                                            Title="Content Strategy & Social Posting"
                                            serviceDescription="At Buzz Global, our experts develop a strategic content roadmap with a mix of content formats like blog posts, case studies, polls and infographics, promotional and conversational posts, and videos and memes to deliver value to your audience. Our strategists even prepare an editorial calendar with detailed content production and publication deadlines for consistent posting."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-3"
                                            btnClass="service__link service__link-color-3"
                                            // shapeImg={shapeImg3}
                                            serviceImage={eCom}
                                            serviceIconBg={shapeIcon4}
                                            Title="Social Media Analytics"
                                            serviceDescription="We don’t believe in guesswork. At Buzz, our social media analysts leverage cutting-edge tools to collect and analyze unparalleled data for strategic decision-making. We patiently monitor your social media performance & track engagement, impressions & click-through rates to get a finger on your customers’ social pulse."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-3"
                                            btnClass="service__link service__link-color-3"
                                            // shapeImg={shapeImg3}
                                            serviceImage={uI}
                                            serviceIconBg={shapeIcon1}
                                            Title="Strategy Optimization"
                                            serviceDescription="Social media is ever-evolving and so should your strategy. Therefore, we monitor your social handles often to refine and adjust our approach & uncover deep insights into what’s working, what’s not, and how your audience interacts with your content. So, whether it’s about changing the content style changing the posting schedule, or revising the hashtag strategy, we aim to keep optimizing the strategy for higher visibility. "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service__area service__plr pb-70 p-relative">
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div
                                        className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <div className="service__section-box text-center pb-35">
                                            <h4 className="section-subtitle section-white-bg title-anim">
                                                SMO Service Section
                                            </h4>
                                            <h3 className="section-title title-anim">
                                                Our SMO Services
                                            </h3>
                                            <p className="char-anim-2">
                                                We’re a 100% Social Focused Agency in the USA. Explore our full suite of social media optimization services.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-1"
                                            btnClass="service__link service__link-color-1"
                                            // shapeImg={shapeImg1}
                                            serviceImage={facebook}
                                            serviceIconBg={shapeIcon1}
                                            Title="Facebook Marketing"
                                            serviceDescription="With our world-class Facebook marketing services, we help you to significantly improve visibility, drive sales, and grow your business online. From Facebook ads management, ad copywriting to Landing Page A/B Testing and Analytics and Optimization, we offer everything. Get most of your Facebook account and pitch the right audiences with our expert suggestions."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-2"
                                            btnClass="service__link service__link-color-2"
                                            // shapeImg={shapeImg2}
                                            serviceImage={linkedin}
                                            serviceIconBg={shapeIcon2}
                                            Title="LinkedIn Marketing"
                                            serviceDescription="Step into the professional spotlight. Our LinkedIn strategist will help you increase your share-of-voice & position your brand as an industry leader. From writing featured and promotional content, and sponsored In Mail optimization to creating ads for LinkedIn, we improve your networking, establish thought leadership, and open doors to newer opportunities within your industry vertical."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-3"
                                            btnClass="service__link service__link-color-3"
                                            // shapeImg={shapeImg3}
                                            serviceImage={youtube}
                                            serviceIconBg={shapeIcon3}
                                            Title="YouTube Marketing"
                                            serviceDescription="Lights, camera, action! With our YouTube Marketing services, reach millions of potential customers on the world’s second-largest search engine. Our team specializes in creating customized YouTube channels, integration with Google Analytics, conducting video SEO audits, generating keywords & titles, and using video syndication strategy to optimize videos thoroughly across all platforms."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-4"
                                            btnClass="service__link service__link-color-4"
                                            // shapeImg={shapeImg4}
                                            serviceImage={instagram}
                                            serviceIconBg={shapeIcon1}
                                            Title="Instagram Marketing"
                                            serviceDescription="Does your business have a solid presence on Instagram? If not then you’re missing out on thousands of daily interactions with potential customers. With our tailored marketing strategies, we go beyond posting to promote your brand and reach a comprehensive audience. Leverage our full-service Instagram management solutions and get your brand where your customers already are."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-4"
                                            btnClass="service__link service__link-color-4"
                                            // shapeImg={shapeImg4}
                                            serviceImage={xthread}
                                            serviceIconBg={shapeIcon2}
                                            Title="Twitter (X)"
                                            serviceDescription="Make every tweet count with our X marketing services. Our in-house social media strategists and content creators will help you get the most out of your Twitter profile, and foster a connection with your potential customers with unique and crisp content & bring you closer to your marketing objectives. We even run paid and organic campaigns to find out how many people you’ve reached and check your account’s monthly performance."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-4"
                                            btnClass="service__link service__link-color-4"
                                            // shapeImg={shapeImg4}
                                            serviceImage={pinterest}
                                            serviceIconBg={shapeIcon3}
                                            Title="Pinterest Marketing"
                                            serviceDescription="We build relationships not just links. Explore our professional Pinterest marketing strategy to improve your brand awareness & establish your brand in your target group. Our experts will help you with complete account setup and optimization, account audit, image creation, campaign management, E-commerce integration & Analytics, and Reporting."
                                        />
                                    </div>
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                                        data-wow-duration="1.1s"
                                    >
                                        <SingleServiceFour
                                            titleClass="service__title service__title-color-4"
                                            btnClass="service__link service__link-color-4"
                                            // shapeImg={shapeImg4}
                                            serviceImage={tiktok}
                                            serviceIconBg={shapeIcon4}
                                            Title="TikTok Marketing"
                                            serviceDescription="Build your presence on the fast-growing platform through high-quality content & connect with younger demographics. Best for B2C and E-commerce brands, our TikTok Marketing services will curate a content strategy that aligns with your business objective to plan, create, and market bold content and genZ visuals. From TikTok Marketing Strategy & Analytics, and Influencer Marketing to TikTok Media Buying & Creative, we offer everything for you. "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 mb-30">
                            <SmmFAQ />
                        </div>
                    </div>
                </div>
            </div>
            <CTA />
        </main>
    );
}

export default smmServices;

import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const SeoFaq = (props) => {
    const { itemClass } = props;
    return (
        <div className={itemClass ? itemClass : 'it-custom-accordion'}>
            <Accordion className="accordion" preExpanded={'a'}>
                <AccordionItem className="accordion-items" uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Is it necessary to hire an SEO agency, or can I do SEO on my own?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>If you have a small business, you can do SEO on your own using various free and paid tools. However, if your business is in a very competitive market and you’re serious about growth, then hire an SEO agency for professional expertise.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="b">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> How much does SEO cost?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Well, it depends. SEO costing is like a pizza – customizable! You can go either for one particular slice or for an entire pizza. Typically, the pricing is calculated depending on how many resources and effort are required to achieve results for your business.</p>
                        <ul className='faq-wrapper'>
                            <li>Size of your website</li>
                            <li>Goals for campaigns and business objectives</li>
                            <li>Services you need like – On Page, Off-Page, Local, Global, or Technical SEO</li>
                            <li>Target market and audience</li>
                        </ul>
                        <p>P.S. Different SEO agencies offer different rates for hourly, project-based packages, and retainer basis.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="c">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Can you optimize Google My Business listings?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Yes. We can help you optimize your Google My Business profile. We’ll start with:</p>
                        <ul className='faq-wrapper'>
                            <li>Sign in to your GMB dashboard</li>
                            <li>Complete every section of your GMB account</li>
                            <li>Upload new photos and posts weekly</li>
                            <li>Answer questions</li>
                            <li>Maintain your overall business profile & more</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="d">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> How do you measure the success of online marketing campaigns?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>We define your KPIs indicators based on metrics like organic traffic, user behavior metrics, and keyword tracking. This helps track your campaign’s performance and ensures your strategy stays on target.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="e">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What metrics do you include to analyze the user behavior of my website?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>At Buzz, we constantly monitor and measure the success of your online campaigns by:</p>
                        <ul className='faq-wrapper'>
                            <li>Setting OKRs and SMART goals</li>
                            <li>Average session duration - the average time a user spends on your website</li>
                            <li>Exit rate on your site – no. of visitors who exit from a particular page</li>
                            <li>Bounce rate - the percentage of people who leave your website after viewing just one page</li>
                            <li>Traffic & conversion rate – no. of website visitors who are converted into leads or customers during a campaign</li>
                            <li>Page Views – total no. of website pages that visitors view</li>
                            <li>New vs Returning visitors</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default SeoFaq;

import React from 'react';

const Work = () => {
  return (
    <div className="work-3__area work-3__inner-wrapper work-3__plr work-3__space">
      <div className="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="project-2__section-box text-center pb-30 section-title-fixed-width">
              <h4 class="section-subtitle title-anim">Our Approach</h4>
              <p>We embrace challenges with an open mind, using a dynamic framework for innovative problem-solving. Our team thrives on knowledge and growth.</p>
            </div>
          </div>
        </div>
        <div className="row row-cols-xl-3 row-cols-md-2 justify-content-center our-approch-wrapper">
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-1 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                  <div className="work-3__icon icon-color-1">
                    <span><i className="fas fa-lightbulb-on"></i></span>
                  </div>
                  <div className="work-3__title">
                    <h4 className="work-3__section-title"><a href="#">Adaptable</a></h4>
                  </div>
                </div>
                <div className="work-3__right-text">
                  <p>Versatility is key to our approach, allowing us to adjust ideas based on data and research, ensuring effective and informed implementation.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-2 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-cogs"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">Interdisciplinary</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>Our team of experts across various fields collaborates closely, sharing insights and best practices to enhance our collective vision.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-3 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-chart-bar"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">Process-Oriented</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>We continually refine our processes, adapting to technological and trend shifts, ensuring our methodology evolves from research to execution and beyond.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
import React from 'react';
import {Helmet} from "react-helmet";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CaseStudiesMain from './CaseStudiesMain';

function CaseStudies() {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Case Studies</title>
          <meta name="description" content="Full service marketing partner That Will Help Grow to Are Your Busines" />
          <link rel="canonical" href={`${window.location.host}/case-studies`}/>
      </Helmet>
      <Header
        parentMenu="about"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <CaseStudiesMain />
          <Footer />
        </div>
      </div>
          
    </>
  )
}

export default CaseStudies
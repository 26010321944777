import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
// import bgImg from "../../assets/img/cta/cta-bg-2.png";
// import CTA from "../../components/CTA";
import CTA from '../home/CtaSection';
// import Faq from "../../components/Faq";

import { Link } from 'react-router-dom';

// import BrandDesignStrategy from "../../assets/img/service/Brand-Design-Strategy.png";
// import serviceImg2 from "../../assets/img/service/service-details-2.jpg";
import SingleServiceFour from "../../components/Service/SingleServiceFour";

// import shapeImg1 from "../../assets/img/service/sv-item-shape-1.png";
// import shapeImg2 from "../../assets/img/service/sv-item-shape-2.png";
// import shapeImg3 from "../../assets/img/service/sv-item-shape-3.png";
// import shapeImg4 from "../../assets/img/service/sv-item-shape-4.png";
// import shapeImg5 from "../../assets/img/service/sv-item-shape-5.png";
// import shapeImg6 from "../../assets/img/service/sv-item-shape-6.png";

import shapeIcon1 from "../../assets/img/service/sv-icon-shape-7.png";
import shapeIcon2 from "../../assets/img/service/sv-icon-shape-8.png";
import shapeIcon3 from "../../assets/img/service/sv-icon-shape-9.png";
import shapeIcon4 from "../../assets/img/service/sv-icon-shape-10.png";
import ourCompany from '../../assets/img/company/our-company.png';
// import social from "../../assets/img/service/social.png";
// import shapeIcon6 from "../../assets/img/service/sv-icon-shape-12.png";


import eCom from '../../assets/img/design/Design-Services/ecom.png';
import mobileDev from '../../assets/img/design/Design-Services/mobile-dev.png';
import uI from '../../assets/img/design/Design-Services/UI.png';
import webDesign from '../../assets/img/design/Design-Services/web-design.png';

import deploy from '../../assets/img/design/Strategy/deployment.png';
import designdev from '../../assets/img/design/Strategy/design-dev.png';
import info from '../../assets/img/design/Strategy/information-archi.png';
import quality from '../../assets/img/design/Strategy/quality.png';
import strategy from '../../assets/img/design/Strategy/strategy.png';
import support from '../../assets/img/design/Strategy/support.png';

import angular from '../../assets/img/design/languages/angular.png';
import drupal from '../../assets/img/design/languages/drupal.png';
import joomla from '../../assets/img/design/languages/joomla.webp';
import laravel from '../../assets/img/design/languages/laravel.png';
import mobileapp from '../../assets/img/design/languages/mobile-app.png';
import mongo from '../../assets/img/design/languages/mongo.png';
import nodejs from '../../assets/img/design/languages/nodejs.png';
import payment from '../../assets/img/design/languages/payment.png';
import php from '../../assets/img/design/languages/php.png';
import react from '../../assets/img/design/languages/react.webp';
import restapi from '../../assets/img/design/languages/restapi.png';
import shopify from '../../assets/img/design/languages/shopify.png';
import shopifyplus from '../../assets/img/design/languages/shopifyplus.png';
import wordpress from '../../assets/img/design/languages/wordpress.png';
import PaidMediaFaq from "../../components/Faq/paidMedia-Faq";

function paidmediaServices() {
  return (
    <main>
      <Breadcrumb pageTitle="Paid Media" />
      <div className="service-details__area service-details__plr mt-110 mb-30">
        <div className="container-fluid">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
                  <div className="service-details__section-box">
                    <h4 className="section-title text-center">
                    Dominate Search Results with Our Strategic PPC Management Services
                    </h4>
                    <p className="text-center">
                      {" "}
                      We’ve nailed the perfect formula to boost your conversion and improve traffic with cutting-edge tools and technologies that put your brand at the top of search results. To know more, call Today!
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
    

          <div className="company__area pt-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <h4 className="section-about-text char-anim">Best PPC Agency in the USA</h4>
                        <div className="company__left-img text-center text-xl-start">
                            <img src={ourCompany} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="company__section-box">
                            
                            <p className="char-anim-2">
                            Buzz Global is a leading PPC Agency that helps you attain high-quality leads and boost sales with data-driven PPC campaigns. Whether you’re a startup, a newly established business, or seeking to acquire your initial client base, we’re here to help.
                              </p>
                              <p className="char-anim-2">
                              Our comprehensive Pay Per Click Marketing Services includes PPC account setup, conversion tracking, bid management, LinkedIn ads, Google Ads, Meta Ads, Binge Ads, YouTube Ads & ad copy optimization, and testing to drive measurable and targeted website traffic for your businesses.
                              </p>
                              <p className="char-anim-2">
                              Our dedicated PPC consultants study your industry landscape and plan, strategize, launch, and manage new PPC campaigns, ensuring that every dollar of your budget is spent wisely.
                              </p>
                              <p className="char-anim-2">
                              At Buzz Global, we offer distinct PPC services for small, medium, and large enterprises and strive to maximize your return on investment with tailored ad campaign strategies.
                              </p>
                              <p className="char-anim-2">
                              Leverage our PPC marketing services to achieve full-funnel ROI tracking and optimize your ad strategies to boost your business with precision-powered PPC ads.
                              </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <div className="row" style={{ marginTop: "100px" }}>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                      Our PPC Services
                      </h4>
                      <h3 className="section-title title-anim">
                      Our Paid Marketing Services Offers
                      </h3>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage= {uI}
                      serviceIconBg={shapeIcon1}
                      Title="Google Ads"
                      serviceDescription="Want to be seen by millions of people exactly when they need you? Our expertise will get your PPC campaign off the ground and generate revenue from the start. As a reputed Paid Advertising Agency, we do everything from single ad campaigns, geo-targeting & local ads to ad copywriting and design to turn your Google Ads into profit for your business."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage= {webDesign}
                      serviceIconBg={shapeIcon2}
                      Title="Meta Ads"
                      serviceDescription="Why stop at just likes? At Buzz Global, we turn those thumbs-ups into actual conversions with our meta ads services. From strategic planning to cross-platform integration, we offer a full spectrum of advertising opportunities within the Meta ecosystem to achieve unparalleled success in the digital landscape. Whether it’s targeted ads on Facebook or reel marketing on Instagram, we’re here to help."
                    />
                  </div>
                  
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={eCom}
                      serviceIconBg={shapeIcon4}
                      Title="LinkedIn ads"
                      serviceDescription="Want to generate quality B2B leads for your business? With our ad specialists, we help you get your brand in front of high-level executives and the right decision-makers to reach B2B clientele. At Buzz, we conduct LinkedIn-sponsored content Ads, In-Mail leads Ads, Website Visit Ads, Text, video, and carousel ads to improve visibility among brands and promote your business significantly."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={uI}
                      serviceIconBg={shapeIcon1}
                      Title="Bing Ads"
                      serviceDescription="Establish your presence and stand out in a less crowded search engine with our Binge Ads services. Our expertise with Bing ads executes high-volume and complex campaigns for both B2C and B2B verticals. We help you with initial planning and setup, campaign development, auditing, and optimization for driving your business growth."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={eCom}
                      serviceIconBg={shapeIcon2}
                      Title="Amazon Ads"
                      serviceDescription="Meet your customers where they are with our Amazon Ads marketing services. Our tailored ad campaigns help you reach your target audience and lead them to your listings. From Amazon Demand-Side-Platform, Sponsored Brands, Products, and Displays, to Video and Audio Ads with content marketing, we strive to open new avenues and opportunities for your business and brand."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={webDesign}
                      serviceIconBg={shapeIcon4}
                      Title="YouTube Ads"
                      serviceDescription="It’s time to go viral. It’s time to create buzz with YouTube ads. Our team of YouTube Advertising experts uses a brilliant combination of content and visuals to launch high-converting landing pages. At Buzz, we audit, redesign, or create new landing pages, prepare well-devised ad strategies, provide hands-on ad setup, and conduct ad monitoring to grow your business on YouTube."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                      PPC Process
                      </h4>
                      <h3 className="section-title title-anim">
                      Our 5-Step PPC Process: <br/> Your Path to Pay-Per-Click Perfection!
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage={strategy}
                      serviceIconBg={shapeIcon1}
                      Title="Research & Keyword Analysis"
                      serviceDescription="We dig into your business, conduct competitive research, analyze your target audience, and get a full understanding of the PPC landscape for your offerings. Our experts then develop a list of keywords that represent your product to maximize your online presence and ROI. We work closely with the client to see what keywords they think are most appropriate for their business category."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage={info}
                      serviceIconBg={shapeIcon2}
                      Title="Bid Management"
                      serviceDescription="Time to place the bets! Our experts meticulously manage your bids ensuring your ads are in the prime spot. We determine your bidding strategy considering your budgeted requirement and in alignment with KPIs for effective campaign management."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={designdev}
                      serviceIconBg={shapeIcon3}
                      Title="Landing Page Optimization"
                      serviceDescription="Got an amazing ad but a boring landing page? Our team of strategists makes an attractive landing page incorporating the targeted keywords into your content, conducts landing page audits, looks after speed optimization, CTA placements, pop-ups designs, and more to turn your website into a powerful lead generator for your business,"
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={quality}
                      serviceIconBg={shapeIcon4}
                      Title="Campaign Management & Analysis"
                      serviceDescription="We don’t just set and forget. Our experts vigilantly manage and optimize your ongoing campaign– on a daily, weekly, or monthly basis. Curious about how ads are performing? We conduct detailed cost and performance analysis to examine conversions, cost per click (CPC), and click-through rate (CTR) to improve the quality score of your website"
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      serviceImage={support}
                      serviceIconBg={shapeIcon1}
                      Title="Transparent Reporting"
                      serviceDescription="No secrets here. We transparently share detailed reporting with our clients to compare investment and its ROI highlighting key metrics and insights along with the best ad groups, ads, and keyword performers. Based on that, we collaborate with you to plan, refine, and remarket your next campaigns for better and higher results than before."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 mb-30">
              <PaidMediaFaq />
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </main>
  );
}

export default paidmediaServices;

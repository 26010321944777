import React from 'react';
import { Link } from "react-router-dom";

function CaseStudyList(props) {
    const { caseData } = props;

    const truncateText = (text, limit) => {
      return text.length > limit ? text.slice(0, limit) + '...' : text;
    };

  return (
    <>
    {
       caseData?.data?.length > 0 && caseData?.data.map((item, index) => {
        return(
            <div
            className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <div className="product__item">
              <div className="product__img">
              <Link to={`/case-studies/${item.slug}`}>
                <img loading="lazy" src={item.featuredImage ? item.featuredImage : "https://buzzglobal.eres.com/static/media/blog-details-2.db98ddbdc188a7b2045f.jpg"} alt="" />
              </Link>
              </div>
              <div className="product__content">
                <div className="product__content-text">
                  <h4>
                    <Link to={`/case-studies/${item.slug}`}>{item.title}</Link>
                  </h4>
                  <p>{truncateText(item.shortText, 80)}</p>
                </div>
                <div className="product__button">
                  <Link className="yellow-btn-sm" to={`/case-studies/${item.slug}`}>
                    Ream more
                </Link>
                </div>
              </div>
            </div>
          </div>
        )
       }) 
    }
    </>
        
  )
}

export default CaseStudyList
import React from 'react';
import emailjs from 'emailjs-com';

import shapeImg1 from '../../assets/img/contact/ct-shape-1.png';
import shapeImg2 from '../../assets/img/contact/ct-shape-2.png';
import shapeImg3 from '../../assets/img/contact/ct-shape-3.png';
import shapeImg4 from '../../assets/img/contact/ct-shape-4.png';

const ContactTwo = (props) => {
	const { itemClass } = props;

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('', '', e.target, '')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        e.target.reset()
    } 

	return (
		<div className={itemClass ? itemClass : 'contact__area contact__plr-2 mt-100 mb-100 p-relative fix'}>
            <div className="contact__shape-1 d-none d-lg-block">
                <img src={shapeImg1} alt="" />
            </div>
            <div className="contact__shape-2 d-none d-md-block">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="contact__shape-3 d-none d-md-block">
                <img src={shapeImg3} alt="" />
            </div>
            <div className="contact__shape-4 d-none d-md-block">
                <img src={shapeImg4} alt="" />
            </div>
            <div className="contact__border">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-8 col-lg-7">
                            <div className="contact__section-title pb-10">
                                <h4 className="section-subtitle char-anim">Get in Touch</h4>
                                <h4 style={{ lineHeight: "40px" }}> Let’s discuss how Buzz Global can help you - </h4>
                                <h3 className="animate-charcter">Attract Audience. Build Brand. Create Campaigns.</h3>
                            </div>
                            <div className="contact__text">
                                <p className="char-anim-2"> Got a solid project in mind? Have a question that’s keeping you up at night? Or just want to say hi? We’re
 all ears! Whether you’re ready to launch something epic or just want to revamp or redesign, drop us a
 line.</p> 
                            </div>
                            <div className="contact__section-title pb-10">
                                <h3 className="animate-charcter">Pitch Us. We Don’t Bite.</h3>  
                            </div>
                            <div className="contact__text">
                                <p className="char-anim-2"> Drop us a line or give us a heads-up if you’re ready to bring your vision to life. Whether it’s a quick
                                consultation or a full-blown project, we’re here and ready to roll! Let’s chat and make this happen.</p> 
                            </div>
                            <div className="contact__form wow animate__fadeInUp" data-wow-duration="1.1s">
                                <form action="#" onSubmit={sendEmail}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="postbox__contact-input">
                                            <input type="text" placeholder="Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="postbox__contact-input">
                                            <input type="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="postbox__contact-select">
                                        <select id="serviceid" name="services">
                                            <option value="" disabled selected>Service interested in?</option>
                                            <option value="SEO">SEO</option>
                                            <option value="Web Development">Web Development</option>
                                            <option value="Email Marketing">Email Marketing</option>
                                            <option value="Paid Advertising">Paid Advertising</option>
                                            <option value="Design">Design</option>
                                            <option value="Hire a Resource/Team">Hire a Resource/Team</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="postbox__contact-input">
                                            <input type="number" placeholder="Phone" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="postbox__contact-textarea">
                                            <textarea name="comments" placeholder="Tell us more about your requirement"></textarea>
                                        </div>
                                    </div>
                                </div>                    
                                </form>
                            </div>
                            <div className="contact__button wow animate__fadeInUp" data-wow-duration="1.1s">
                                <a className="main-btn-sm  tp-btn-hover alt-color" href="#"><span>Submit Request</span><b></b></a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="contact__left-side">
                            <div className="contact__item d-flex align-items-center wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <div className="contact__icon">
                                        <span><a href="#"><i className="fal fa-map-marker-check"></i></a></span>
                                    </div>
                                    <div className="contact__content">
                                        <h4 className="contact__title-sm">Address</h4>
                                        <span>
                                            Miami, FL, USA
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="contact__item d-flex align-items-center wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <div className="contact__icon">
                                        <span><a href="#"><i className="fal fa-phone-alt"></i></a></span>
                                    </div>
                                    <div className="contact__content">
                                        <h4 className="contact__title-sm">Phone</h4>
                                        <span><a href="tel:+125461556695">(+1) 456 1122 7890 </a></span>
                                    </div>
                                </div> */}
                                <div className="contact__item d-flex align-items-center wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <div className="contact__icon">
                                        <span><a href="#"><i className="fal fa-envelope-open-text"></i></a></span>
                                    </div>
                                    <div className="contact__content">
                                        <h4 className="contact__title-sm">Email</h4>
                                        <span><a href="mailto:careers@buzzglobalmarketing.com ">digital@buzzglobalmarketing.com</a></span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default ContactTwo;





import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
// import bgImg from "../../assets/img/cta/cta-bg-2.png";
// import CTA from "../../components/CTA";
import CTA from '../home/CtaSection';
// import Faq from "../../components/Faq";

import { Link } from 'react-router-dom';

// import BrandDesignStrategy from "../../assets/img/service/Brand-Design-Strategy.png";
// import serviceImg2 from "../../assets/img/service/service-details-2.jpg";
import SingleServiceFour from "../../components/Service/SingleServiceFour";

// import shapeImg1 from "../../assets/img/service/sv-item-shape-1.png";
// import shapeImg2 from "../../assets/img/service/sv-item-shape-2.png";
// import shapeImg3 from "../../assets/img/service/sv-item-shape-3.png";
// import shapeImg4 from "../../assets/img/service/sv-item-shape-4.png";
// import shapeImg5 from "../../assets/img/service/sv-item-shape-5.png";
// import shapeImg6 from "../../assets/img/service/sv-item-shape-6.png";

import shapeIcon1 from "../../assets/img/service/sv-icon-shape-7.png";
import shapeIcon2 from "../../assets/img/service/sv-icon-shape-8.png";
import shapeIcon3 from "../../assets/img/service/sv-icon-shape-9.png";
import shapeIcon4 from "../../assets/img/service/sv-icon-shape-10.png";
import ourCompany from '../../assets/img/company/our-company.png';
// import social from "../../assets/img/service/social.png";
// import shapeIcon6 from "../../assets/img/service/sv-icon-shape-12.png";


import eCom from '../../assets/img/design/Design-Services/ecom.png';
import mobileDev from '../../assets/img/design/Design-Services/mobile-dev.png';
import uI from '../../assets/img/design/Design-Services/UI.png';
import webDesign from '../../assets/img/design/Design-Services/web-design.png';

import deploy from '../../assets/img/design/Strategy/deployment.png';
import designdev from '../../assets/img/design/Strategy/design-dev.png';
import info from '../../assets/img/design/Strategy/information-archi.png';
import quality from '../../assets/img/design/Strategy/quality.png';
import strategy from '../../assets/img/design/Strategy/strategy.png';
import support from '../../assets/img/design/Strategy/support.png';

import angular from '../../assets/img/design/languages/angular.png';
import drupal from '../../assets/img/design/languages/drupal.png';
import joomla from '../../assets/img/design/languages/joomla.webp';
import laravel from '../../assets/img/design/languages/laravel.png';
import mobileapp from '../../assets/img/design/languages/mobile-app.png';
import mongo from '../../assets/img/design/languages/mongo.png';
import nodejs from '../../assets/img/design/languages/nodejs.png';
import payment from '../../assets/img/design/languages/payment.png';
import php from '../../assets/img/design/languages/php.png';
import react from '../../assets/img/design/languages/react.webp';
import restapi from '../../assets/img/design/languages/restapi.png';
import shopify from '../../assets/img/design/languages/shopify.png';
import shopifyplus from '../../assets/img/design/languages/shopifyplus.png';
import wordpress from '../../assets/img/design/languages/wordpress.png';
import CreativeContentFAQ from "../../components/Faq/creativeContent-Faq";

function contentServices() {
  return (
    <main>
      <Breadcrumb pageTitle="Creative & Content" />
      <div className="service-details__area service-details__plr mt-110 mb-30">
        <div className="container-fluid">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
                  <div className="service-details__section-box">
                    <h4 className="section-title text-center">
                    Content Strategy So Sharp, You'll Need a Band-Aid
                    </h4>
                    <p className="text-center">
                      {" "}
                      Our content strategists and excellent copywriters craft compelling stories that captivate, convert, and keep your audience hooked with our cutting-edge content strategy solutions.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
    

          <div className="company__area pt-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <h4 className="section-about-text char-anim">Best Content Marketing Agency To Count On</h4>
                        <div className="company__left-img text-center text-xl-start">
                            <img src={ourCompany} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="company__section-box">
                            
                              <p className="char-anim-2">
                                Buzz Global is a leading content marketing agency in the USA that fuels your brand with a result-driven approach. Be it content strategy consulting, SEO-focused content, in-depth blog posts website content an eBook that adds value, or an email series – we deliver diverse people-inspired content solutions to our clients.
                              </p>
                              <p className="char-anim-2">
                              At Buzz, we follow an agile content strategy design and development methodology that emphasizes on flexibility and continuous improvement based on real-time updates. Having more than decades of experience, our cross-functional team of experts can iterate and develop content and design faster to keep your brands ahead of the curve.
                              </p>
                              <p className="char-anim-2">
                              Our content strategists understand your industry landscape and generate and distribute a diverse range of content that is strategically planned with a collaborative, and client-focused approach to boost engagement & make meaningful connections.
                              </p>
                              <p className="char-anim-2">
                              As leaders in content marketing and branding, we prepare personalized digital content strategies aligning with your brand’s voice, style, and guidelines to weave your brand’s tale into something unforgettable.
                              </p>
                              <p className="char-anim-2">
                              Yes, we make content the reason to search for your brand. Can’t believe it? Hire us and watch your brand visibility soar higher!
                              </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <div className="row" style={{ marginTop: "100px" }}>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                      Services
                      </h4>
                      <h3 className="section-title title-anim">
                      From Words to Wins: <br/> We Offer Services That Delivers Value
                      </h3>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage= {uI}
                      serviceIconBg={shapeIcon1}
                      Title="Content Strategy"
                      serviceDescription="We follow an audience-centric approach to develop a comprehensive content strategy that aligns with your business. At Buzz, we analyze your competitive landscape, operationalize your content production process, and identify KPIs, content theme, editorial tone, formats and distribution to build a value-driven content program for your business."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage= {webDesign}
                      serviceIconBg={shapeIcon2}
                      Title="Content Audit"
                      serviceDescription="How’s your content performing? At Buzz, we’ve a team of content writers, SEO strategists, and industry experts who look after content quality, SEO, and strategic consistency to determine how each content asset can be improved. We even keep tabs on social media metrics, backlinks, traffic and engagement rates to monitor & analyze overall content performance for data driven decision making."
                    />
                  </div>
                  
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={eCom}
                      serviceIconBg={shapeIcon4}
                      Title="Branded Content"
                      serviceDescription="Does your brand have a story? Or want to create one? We’ll work closely with you & make sure it’s heard with our branded content services. We offer a full suite of branded content – Articles, Newsletters, Sponsored Content, Infographics, E-books, Website Content, Presentations and more that resonates with your audience and strengthens your brand’s identity."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={uI}
                      serviceIconBg={shapeIcon1}
                      Title="Videography"
                      serviceDescription="Lights. Camera. Action! With our videography services, we bring your stories to life with impressive visuals. From social media reels, website videos, and documentary films to commercials, we enhance your brand and messaging with professional, award-winning photography and videography solutions that capture attention and drive results."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                      Strategy/Process Section
                      </h4>
                      <h3 className="section-title title-anim">
                      The Digital Content Strategy No One Can Ignore
                      </h3>
                      <p className="char-anim-2">
                      From videos to full-length articles, make substantial investments in your business with a thoughtful and no-nonsense but data-driven digital content strategy. Check how we do this -:
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage={strategy}
                      serviceIconBg={shapeIcon1}
                      Title="Discover"
                      serviceDescription="Our team of content marketers dive deep to understand your overarching goals and specific objectives of your business. We scout for – What makes your brand tick? What customer pains are we trying to solve? Who do you need to reach? What are your short and long-term objectives? We get to the core of these questions to lay a solid foundation."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage={info}
                      serviceIconBg={shapeIcon2}
                      Title="Define"
                      serviceDescription="Next, we turn these insights into actions. We identify your target audiences – their interest, preferences, and problems, and importantly where do they spend time online. Our content strategists create a buyer’s persona to create funnel-optimized content. It’s all about setting the right direction, pinpointing your target audience, and establishing the metrics that matter most."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={designdev}
                      serviceIconBg={shapeIcon3}
                      Title="Develop"
                      serviceDescription="Now, we roll up our sleeves and develop a written roadmap for a clear direction. Our experts identify the best channels &  start implementing a fully-fledged content marketing strategy with a full suite of SEO essentials. Once everything is developed and in place, our writers share the timeline, establish the editorial guidelines, and write the first draft putting visuals in its place."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={quality}
                      serviceIconBg={shapeIcon4}
                      Title="Deploy"
                      serviceDescription="It’s time to put everything into motion and make it measurable. At Buzz, we launch the strategy with precision and keep monitoring your KPIs - consumption, sharing, lead generation, and sales. Our content analysts conduct A/B testing to track and analyze the performance of each version – heading, image, color, content or placement of CTA to optimize & repurpose or remark your content regularly."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h3 className="section-title title-anim">
                      Benefit Section or Importance of Content
                      </h3>
                      <p className="char-anim-2">
                      Attract Long Term Clients With the Right Content Marketing Strategy
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      // serviceImage={wordpress}
                      // serviceIconBg={shapeIcon1}
                      Title="Establish Authority and Thought Leadership"
                      serviceDescription="Want to be seen as the go-to expert in your field? It's important to consistently publish insightful and valuable content to tap into the psychology of consumer behavior.  With every content piece, you can offer a new perspective to get an edge and effectively distribute it across various platforms. Through this, you can confidently display your expertise to your audience with solid authority and credibility."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      // serviceImage={react}
                      // serviceIconBg={shapeIcon2}
                      Title="Enhance Your Brand Visibility"
                      serviceDescription="Content marketing is all about reaching a wider audience base by creating SEO optimized content across different channels. It’s your ticket to make your brand visible in SERPs that tell people you exist and that you know what you’re doing. Want your brand to pop up when people search for solutions? It’s time to spread the word."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      // serviceImage={nodejs}
                      // serviceIconBg={shapeIcon3}
                      Title="Connects with Ad-Wary Audiences"
                      serviceDescription="Tired of people who skip ads? Content marketing is the antidote that helps you reach buyers who watch videos on fast-forward mode. So, by offering valuable and informative content, you can hook your audience who don’t trust ads or refuse to engage with them. Make your audience actually listen that they want to."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      // serviceImage={angular}
                      // serviceIconBg={shapeIcon4}
                      Title="Lead Generations & Conversions"
                      serviceDescription="Turn your browsers into buyers by implementing the best content marketing strategy. And, the best way to improve your leads and conversions is to deliver quality content regularly. Write content that directs users towards a specific action, solidifying your brand. Also, the more quality content you create, the larger your audience will grow, and the better your sales will be."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 mb-30">
              <CreativeContentFAQ />
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </main>
  );
}

export default contentServices;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import CaseStudyList from "../../components/CaseStudy/CaseStudyList";



function CaseStudiesProjects() {
	const [caseData, setCaseData] = useState([]);

	const fetchCaseStudyList = async () => {
		try {
			let data = JSON.stringify({
				"filter": {
				  "filter": {
					"logic": "and",
					"offset": 0,
					"limit": 25,
					"filters": [],
					"sort": [
					  {
						"field": "createdAt",
						"table": "Posts",
						"dir": "desc"
					  }
					]
				  }
				},
				"menuId": "c1259c5e-6b38-4bf5-a784-869a0b6c124a",
				"action": "command",
				"command": [
				  	{
						"agent": "getCaseStudyLists",
						"appName": "fileSharing",
						"folder": "Posts/Case Study"
					}
				]
			  });
			  
			  let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: 'https://buzzglobal.eres.com/api/pipeline',
				headers: { 
					'Content-Type': 'application/json'
				  },
				data : data
			  };
			
			const response = await axios.request(config);
			if(response.statusText === "OK"){
				setCaseData(response?.data)
			} else {
				console.log(response)
			}
		  } catch (err) {
			console.log(err)
		  }
	  }
	
	useEffect(() => {
		fetchCaseStudyList();
	}, []);
  return (
    <div className="product__area product__plr mt-100 mb-70">
      <div className="container-fluid">
        <div className="row">
			<CaseStudyList caseData={caseData} />
        </div>
      </div>
    </div>
  );
}

export default CaseStudiesProjects;

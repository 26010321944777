import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import {Helmet} from "react-helmet";
import Breadcrumb from '../../components/Breadcrumb';
import CTA from '../../components/CTA';
import bgImg from '../../assets/img/cta/cta-bg-2.png';
import moment from 'moment/moment';

function CaseStudiesDetails() {
    const { slug } = useParams();
	const [data, setData] = useState({});
	const [recentStudy, setRecentStudy] = useState([])

	const fetchCaseStudyDetails = async () => {
		try {
			let data = JSON.stringify({
				"filter": {
				  "filter": {
					"logic": "and",
					"offset": 0,
					"limit": 1,
					"filters": [
						{
							"field": "slug",
							"operator": "eq",
							"table": "Posts",
							"value": slug
						}
					],
					"sort": [
					  {
						"field": "createdAt",
						"table": "Posts",
						"dir": "desc"
					  }
					]
				  }
				},
				"menuId": "c1259c5e-6b38-4bf5-a784-869a0b6c124a",
				"action": "command",
				"command": [
				  {
					"agent": "getCaseStudyLists",
					"appName": "fileSharing",
					"folder": "Posts/Case Study"
				  }
				]
			  });
			  
			  let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: 'https://buzzglobal.eres.com/api/pipeline',
				headers: { 
					'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2YWE4MGU2YzA4YWI5MzczNThiYTcwNiIsInV1aWQiOiJlMDc5MjI1MS1mZmJmLTQ4NjQtOGQ5MC1hNGQ0ZTYxMmZjZTQiLCJlbWFpbCI6ImFkbWluQGVyZXMuY29tIiwicm9sZSI6InN1cGVyYWRtaW4iLCJpYXQiOjE3MjI0OTIxNzgsImV4cCI6MTcyMjU3ODU3OH0.cMuOnggQEPVVaFFSp1iSMdwPS6JQ-x-B1Gvrvwn5BlI', 
					'Content-Type': 'application/json'
				},
				data : data
			  };
			  
			const response = await axios.request(config);
			if(response.statusText === "OK"){
				setData(response?.data?.data[0])
			}
			
		  } catch (err) {
			console.log(err)
		  }
	}
	useEffect(() => {
	fetchCaseStudyDetails();
	fetchRecentCaseStudies()
	}, []);
	const fetchRecentCaseStudies = async () => {
		try {
			let data = JSON.stringify({
				"filter": {
				  "filter": {
					"logic": "and",
					"offset": 0,
					"limit": 3,
					"filters": [],
					"sort": [
					  {
						"field": "createdAt",
						"table": "Posts",
						"dir": "desc"
					  }
					]
				  }
				},
				"menuId": "c1259c5e-6b38-4bf5-a784-869a0b6c124a",
				"action": "command",
				"command": [
				  {
					"agent": "getCaseStudyLists",
					"appName": "fileSharing",
					"folder": "Posts/Case Study"
				  }
				]
			  });
			  
			  let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: 'https://buzzglobal.eres.com/api/pipeline',
				headers: { 
				  'Content-Type': 'application/json'
				},
				data : data
			  };
			  
			const responseRecent = await axios.request(config);
			console.log(responseRecent, "responseRecent =====");
			if(responseRecent.statusText === "OK"){
				setRecentStudy(responseRecent?.data)
			}
			
		  } catch (err) {
			console.log(err)
		  }
	  }

  return (
    <main>
			<Helmet>
              <meta charSet="utf-8" />
              <meta name="robots" content="index, follow" />
              <title>Case Studies</title>
			  <meta name="title" content={data.title} />
              <meta name="description" content={data.shortText} />
              <link rel="canonical" href={`${window.location.host}/case-studies`}/>
          	</Helmet>
			<Breadcrumb
				pageTitle= "Case Study Details"
			/>
			
			<div className="postbox__area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-xxl-8 col-xl-8 col-lg-8">
							<div className="postbox__details-wrapper pr-20">
								<article>
									<div className="postbox__thumb w-img wow animate__fadeInUp" data-wow-duration="1.1s">
									<img src={data.featuredImage ? data.featuredImage : "https://buzzglobal.eres.com/static/media/blog-details-2.db98ddbdc188a7b2045f.jpg"} alt="" />
									</div>
									<div className="postbox__details-title-box pb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
									<h4 className="postbox__details-title">{data.title}</h4>
									<p dangerouslySetInnerHTML={{ __html: data.content }} />
									</div>
								</article>
							</div>
						</div>
						<div className="col-xxl-4 col-xl-4 col-lg-4">
							<div className="sidebar__wrapper">
								<div className="sidebar__widget mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
									<div className="sidebar__widge-title-box">
										<h3 className="sidebar__widget-title">Search</h3>
									</div>
									<div className="sidebar__widget-content">
										<div className="sidebar__search">
											<form action="#">
												<div className="sidebar__search-input-2">
													<input type="text" placeholder="Search your keyword..." />
													<button type="submit">
														<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M8.01371 15.2219C11.9525 15.2219 15.1456 12.0382 15.1456 8.11096C15.1456 4.18368 11.9525 1 8.01371 1C4.07488 1 0.881836 4.18368 0.881836 8.11096C0.881836 12.0382 4.07488 15.2219 8.01371 15.2219Z" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
														<path d="M16.9287 16.9996L13.0508 13.1331" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
														</svg>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="sidebar__widget mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
									<div className="sidebar__widge-title-box">
										<h3 className="sidebar__widget-title">Recent Post</h3>
									</div>
									<div className="sidebar__widget-content">
										<div className="sidebar__post rc__post">
											{
												recentStudy?.data?.length > 0 && recentStudy?.data?.map((item, index) => {
													return(
														<div className="rc__post mb-20 d-flex">
															<div className="rc__post-thumb mr-20">
																<Link to={`/blog/${item.slug}`}>
																	<img src={item.featuredImage ? item.featuredImage : "https://buzzglobal.eres.com/static/media/blog-details-2.db98ddbdc188a7b2045f.jpg"} alt="" />
																</Link>
															</div>
															<div className="rc__post-content">
																<h3 className="rc__post-title">
																<Link to={`/blog/${item.slug}`}>{item.title}</Link>
																</h3>
																<div className="rc__meta">
																	<span>{moment(item.createdAt).format('MMM, D YYYY')}</span>
																</div>
															</div>
														</div>
													)
												})
											}
											
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<CTA
				ctaBG={bgImg}
			/>

		</main>
  )
}

export default CaseStudiesDetails
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  blogs: [],
  categories: [],
  loading: false,
  error: false
}

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
      blogList: (state, action) => {
        state.blogs = action.payload
      },
      blogCategory: (state, action) => {
        state.categories = action.payload
      }
    },
  })
  
  
  export const blogAction = blogSlice.actions
  
  export default blogSlice.reducer
import React from 'react';
import Breadcrumb from "../../components/Breadcrumb";
import Brand from '../../components/Brand';
import CaseStudiesProjects from './CaseStudiesProjects';
import CTA from '../../components/CTA';
import bgImg from '../../assets/img/cta/cta-bg-2.png';


function CaseStudiesMain() {
  return (
    <main>
      <Breadcrumb pageTitle="Case Studies" />
      <Brand itemClass="brand-2__area brand-2__space" />
      <CaseStudiesProjects />
      <CTA ctaBG={bgImg} />
    </main>
  );
}

export default CaseStudiesMain
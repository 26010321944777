import React from 'react';
import HomeMain from './HomeMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';


const Home = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="index, follow" />
                <title>Home</title>
                <meta name="description" content="Full service marketing partner That Will Help Grow to Are Your Busines" />
                <link rel="canonical" href={`${window.location.host}`}/>
            </Helmet> 
            <Header
                parentMenu='home'
            />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <HomeMain />
                    <Footer />
                </div>
            </div>
                    
        </>
    );
}

export default Home;

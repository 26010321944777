import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
// import bgImg from "../../assets/img/cta/cta-bg-2.png";
import CTA from '../home/CtaSection';
import Faq from "../../components/Faq";
import { Link } from 'react-router-dom';

// import BrandDesignStrategy from "../../assets/img/service/Brand-Design-Strategy.png";
// import serviceImg2 from "../../assets/img/service/service-details-2.jpg";
import SingleServiceFour from "../../components/Service/SingleServiceFour";

// import shapeImg1 from "../../assets/img/service/sv-item-shape-1.png";
// import shapeImg2 from "../../assets/img/service/sv-item-shape-2.png";
// import shapeImg3 from "../../assets/img/service/sv-item-shape-3.png";
// import shapeImg4 from "../../assets/img/service/sv-item-shape-4.png";
// import shapeImg5 from "../../assets/img/service/sv-item-shape-5.png";
// import shapeImg6 from "../../assets/img/service/sv-item-shape-6.png";

import shapeIcon1 from "../../assets/img/service/sv-icon-shape-7.png";
import shapeIcon2 from "../../assets/img/service/sv-icon-shape-8.png";
import shapeIcon3 from "../../assets/img/service/sv-icon-shape-9.png";
import shapeIcon4 from "../../assets/img/service/sv-icon-shape-10.png";
import ourCompany from '../../assets/img/company/our-company.png';
// import social from "../../assets/img/service/social.png";
// import shapeIcon6 from "../../assets/img/service/sv-icon-shape-12.png";


import eCom from '../../assets/img/design/Design-Services/ecom.png';
import mobileDev from '../../assets/img/design/Design-Services/mobile-dev.png';
import uI from '../../assets/img/design/Design-Services/UI.png';
import webDesign from '../../assets/img/design/Design-Services/web-design.png';

import deploy from '../../assets/img/design/Strategy/deployment.png';
import designdev from '../../assets/img/design/Strategy/design-dev.png';
import info from '../../assets/img/design/Strategy/information-archi.png';
import quality from '../../assets/img/design/Strategy/quality.png';
import strategy from '../../assets/img/design/Strategy/strategy.png';
import support from '../../assets/img/design/Strategy/support.png';

import angular from '../../assets/img/design/languages/angular.png';
import drupal from '../../assets/img/design/languages/drupal.png';
import joomla from '../../assets/img/design/languages/joomla.webp';
import laravel from '../../assets/img/design/languages/laravel.png';
import mobileapp from '../../assets/img/design/languages/mobile-app.png';
import mongo from '../../assets/img/design/languages/mongo.png';
import nodejs from '../../assets/img/design/languages/nodejs.png';
import payment from '../../assets/img/design/languages/payment.png';
import php from '../../assets/img/design/languages/php.png';
import react from '../../assets/img/design/languages/react.webp';
import restapi from '../../assets/img/design/languages/restapi.png';
import shopify from '../../assets/img/design/languages/shopify.png';
import shopifyplus from '../../assets/img/design/languages/shopifyplus.png';
import wordpress from '../../assets/img/design/languages/wordpress.png';

function DevelopmentServices() {
  return (
    <main>
      <Breadcrumb pageTitle="Design & Development" />
      <div className="service-details__area service-details__plr mt-110 mb-30">
        <div className="container-fluid">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
                  <div className="service-details__section-box">
                    <h4 className="section-title text-center">
                      Reliable Custom Web Development Agency
                    </h4>
                    <p className="text-center">
                      {" "}
                      Get high-performing custom website designing and development
                      solutions that deliver fast, responsive, and stunning designs
                      with unparalleled support and maintenance services.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="company__area pt-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <h4 className="section-about-text char-anim">Website Design & Development Company</h4>
                        <div className="company__left-img text-center text-xl-start">
                            <img src={ourCompany} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="company__section-box">
                            
                        <p className="char-anim-2">
                          Buzz Global is a full service website design and development
                          company that creates custom web designs tailored to your
                          unique needs. As a trusted web development company our
                          services are designed to drive your businesses to the
                          forefront of your industry and build a competitive edge in the
                          market.
                        </p>
                        <p className="char-anim-2">
                          We're leveraging some of the powerful technologies like React,
                          NodeJs, WordPress, Drupal, Shopify, PHP, and App Development
                          (Progressive+ Native, iOS, & Android) to build fast, stable,
                          user-friendly, responsive, SEO-friendly, and
                          conversion-focused.
                        </p>
                        <p className="char-anim-2">
                          Our team of experienced web developers and designers plan,
                          design, and develop modern, responsive web applications that
                          help you nail your business objectives. From high-converting
                          landing pages to robust functional management systems, we even
                          provide a full range of hosting and maintenance packages to
                          meet your diverse business needs.
                        </p>
                        <div className="button-wrapper">
                          <b>Want to take your business online? Connect with us now & let’s do it together</b>
                          <div className="button-wrapper">
                            <div className="cta__btn wow animate__fadeInUp serviceDetails-button" data-wow-duration="1.1s">
                              <a href="tel:025461556695" className="white-btn tp-btn-hover alt-color callUS-wrapper"><span>Call Us</span><b></b>
                              </a>
                              <Link to="/contact" className="white-btn tp-btn-hover alt-color"><span>Get Started</span><b></b>
                              </Link>
                            </div>
                          </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <div className="row" style={{ marginTop: "100px" }}>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                        Our Services
                      </h4>
                      <h3 className="section-title title-anim">
                        Why Choose Buzz Global as Your Custom Website Design &
                        Development Partner
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12 wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <div className="service-details__section-box">
                    <p>
                      At Buzz Global, we deliver comprehensive Website Design &
                      Development Services leveraging the latest technology and
                      innovations. Whether you’re looking to develop, redesign,
                      host, maintain, or require support solutions for your
                      website, we’re here to help; ensuring compatible and
                      modern design with high-quality standards. Our services
                      include
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage= {uI}
                      serviceIconBg={shapeIcon1}
                      Title="User Interface Design"
                      serviceDescription="Our UI experts deliver user-focused, customer-centric web design that enhances user experience & engagement. From concept to final design, we ensure your platform looks great on every device that keeps users coming back."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage= {webDesign}
                      serviceIconBg={shapeIcon2}
                      Title="Website Design & CMS Development"
                      serviceDescription="Want to make your website more agile and scalable? At Buzz, we deliver purposeful and customer-first CMS solutions integrated with the latest plugins, modules, themes, extensions, and features to create a unique, and responsive platform for your online business."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={mobileDev}
                      serviceIconBg={shapeIcon3}
                      Title="Native Mobile Applications"
                      serviceDescription="Whether it's a consumer app or an enterprise solution, we build robust, intuitive, and high-performing native apps for iOS & Android environments with flawless user experience to bring your mobile vision to life."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={eCom}
                      serviceIconBg={shapeIcon4}
                      Title="E-commerce Development & Integration"
                      serviceDescription="Build customer loyalty, provide personalized shopping experiences, and respond faster to your customer demands with our user-friendly e-commerce development solutions. Manage your retail business processes like ERM, stock and inventory & CRM with our tailored e-commerce integrated services"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                        Strategy/Process
                      </h4>
                      <h3 className="section-title title-anim">
                        Our Process - <br/>Simple, seamless, streamlined
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage={strategy}
                      serviceIconBg={shapeIcon1}
                      Title="Strategy"
                      serviceDescription="Our experts create a full brief of the idea bank and client requirements. Based on that, we conduct thorough research to define your goals and objectives and understand your target market audience and KPIs. Typically, we translate these into full-fledged technical documents and early-stage prototypes ensuring that they align with your result-driven vision."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage={info}
                      serviceIconBg={shapeIcon2}
                      Title="Information Architecture"
                      serviceDescription="We structure your website and content by creating a user-focused information architecture that makes the product usable and understandable while enhancing user experience. Based on IA, we strategically place all essential creative assets like carousels, static designs, banners, social media creatives, gifs, videography, and long-format content to house the overall website or app's content structure."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={designdev}
                      serviceIconBg={shapeIcon3}
                      Title="Design & Development"
                      serviceDescription="Our team of user experience and usability experts come together to develop wireframes and finalize the
 look and feel of the website. Then, our developers freeze the scope, write clean and efficient code, and
 develop an easy-to-use website that is fast, scalable, responsive, and user-friendly."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={quality}
                      serviceIconBg={shapeIcon4}
                      Title="Quality Assurance"
                      serviceDescription="We don’t just build websites. We even look after perfection. Our QA Analyst conduct thorough functional, performance, regression, manual, and automated tests, ensuring that it perfectly meet the quality standards. From performance testing to security audits, our team ensures your website runs smoothly across all devices without any hassle."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      serviceImage={support}
                      serviceIconBg={shapeIcon1}
                      Title="Support & Maintenance"
                      serviceDescription="After the release, we continuously monitor the performance of your website, fix bugs and errors, and
 provide incessant customer support. Our team looks after every technical aspect of the website ensuring
 there's no disruption or downtime while it’s live."
                    />
                  </div>
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg2}
                      serviceImage={deploy}
                      serviceIconBg={shapeIcon2}
                      Title="Deployment"
                      serviceDescription=" Once everything is fixed and resolved, we successfully deploy your website across all platforms by using
 the latest technologies. To meet the ever changing demands, we can provide you with a small team who
 work closely with you to implement the latest feature updates to your website."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service__area service__plr pb-70 p-relative">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div
                    className="col-xl-10 col-lg-10 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="service__section-box text-center pb-35">
                      <h4 className="section-subtitle section-white-bg title-anim">
                        Technologies We Use
                      </h4>
                      <h3 className="section-title title-anim">
                        We make the web and mobile work for you by leveraging the best technologies and tools
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-1"
                      btnClass="service__link service__link-color-1"
                      // shapeImg={shapeImg1}
                      serviceImage={wordpress}
                      serviceIconBg={shapeIcon1}
                      Title="Wordpress"
                      serviceDescription="WordPress is an excellent open-source and the most popular website-building platform that creates
 dynamic and user-friendly websites for diverse business needs. It is integrated with powerful themes,
 plugins, features, and customization options and therefore, we use WordPress to develop custom
 websites for different industry verticals. From blogging to highly B2B websites, our developers leverage
 scalable solutions for stunning designs and optimized SEO performance."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-2"
                      btnClass="service__link service__link-color-2"
                      // shapeImg={shapeImg2}
                      serviceImage={react}
                      serviceIconBg={shapeIcon2}
                      Title="React"
                      serviceDescription=" As a leading ReactJS development company, we build websites using ReactJS to deliver interactive and
 high-performance websites that are faster and more responsive. We have leading experts with hands-on
 expertise in frameworks like Material UI, Ant Design, and React Bootstrap to create visually appealing
 and functional user interfaces for your web and applications."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-3"
                      btnClass="service__link service__link-color-3"
                      // shapeImg={shapeImg3}
                      serviceImage={nodejs}
                      serviceIconBg={shapeIcon3}
                      Title="NodeJs"
                      serviceDescription="Node.js is an open-source and cross-platform JavaScript runtime environment. It is a popular tool for
 almost any kind of project. At Buzz Global, we use NodeJs to build lightweight, fast, scalable, and
 high-functioning web applications to give your business the right technological innovation. From website
 development to NodeJs migration, maintenance, and overall support, we’re always here to help and
 make your business sustainable."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={angular}
                      serviceIconBg={shapeIcon4}
                      Title="Angular"
                      serviceDescription=" Our web developers specialize in delivering lightweight and cross-platform Angular applications for
 businesses that demand robust and secure solutions. Our top-notch Angular developers help you with
 Single-Page Applications (SPAs), Angular custom mobile app development, PWAs, API Development &
 Integration, and maintenance & support"
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      serviceImage={drupal}
                      serviceIconBg={shapeIcon1}
                      Title="Drupal"
                      serviceDescription="Drupal is our solution to create complex, highly customizable websites and CMSs. From advanced
 module management to building custom website solutions, we cover business needs from every vertical.
 With Drupal, we ensure your website is secure, scalable, and robust, and works more efficiently.
 Whether you want Drupal e-commerce development, theme development, third-party integration, or
 migration to Drupal, we cover everything."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg2}
                      serviceImage={shopify}
                      serviceIconBg={shapeIcon2}
                      Title="Shopify"
                      serviceDescription="Want to build intuitive, sales-driven, and robust online stores for your business? We excel in Shopify
 development solutions. Our team of Shopify developers has years of experience in helping you with
 building custom apps, migration from/to Shopify, and importing & exporting data or responsive and
 functional themes that are developed using HTML, CSS, and Liquid. Whether you’re a startup or an
 established business, we tailor our services to meet your unique needs."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg3}
                      serviceImage={shopifyplus}
                      serviceIconBg={shapeIcon3}
                      Title="Shopify Plus"
                      serviceDescription="Do you own a B2B, B2C, or B2B2C store? Our Shopify Plus Developers will help you with a complete
 e-commerce store setup. From seamless integration of cutting-edge features to bespoke app
 development, secure migration, and unparalleled support and maintenance, we strive to elevate your
 store success coupled with high performance, security, and fast support"
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={mobileapp}
                      serviceIconBg={shapeIcon4}
                      Title="Mobile App Development"
                      serviceDescription="Be it a native mobile app for Android or iOS, we deliver human-centric next-generation mobile app
 solutions across multiple platforms through a design thinking approach. From MVP to custom app
 development, along with testing and QA, we offer end-to-end mobile app solutions catering to your
 diverse business needs"
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      serviceImage={laravel}
                      serviceIconBg={shapeIcon1}
                      Title="Laravel"
                      serviceDescription="From Laravel enterprise solutions, third-party integrations to customized Laravel web development,
 RESTful APIs, and data migration, we offer 360-degree solutions for all your development needs. Our
 team of Laravel developers creates stunning apps and websites by using modern technology and tools to
 scale up your business in this competitive landscape."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg2}
                      serviceImage={mongo}
                      serviceIconBg={shapeIcon2}
                      Title="MongoDB"
                      serviceDescription=" At Buzz Global, we offer high-tech & scalable mobile and web applications with next-gen solutions to
 help you manage your database efficiently. As a MongoDB development company, we offer services such
 as MEAN stack development, RESTful API development, Big Data efficiency, seamless integration, and
 MongoDB database maintenance & support to empower your business with pragmatic apps that deliver
 results."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg3}
                      serviceImage={joomla}
                      serviceIconBg={shapeIcon3}
                      Title="Joomla"
                      serviceDescription="Joomla is one of the largest content management systems after WordPress. As a Joomla development
 company, we deliver high-performance, flexible, robust, and customizable websites by using feature-rich
 templates, and user-friendly interfaces. Our world-class Joomla dedicated developers, analysts, and QAs
 work together to achieve your enterprise goals"
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg4}
                      serviceImage={php}
                      serviceIconBg={shapeIcon4}
                      Title="PHP"
                      serviceDescription="PHP is a widely used server-side scripting language used to build dynamic and vibrant websites and
 applications. At Buzz Global, we empower your business by providing database-driven and robust PHP
 web development solutions. We offer PHP Solutions with Advanced AI/ML Capabilities, PHP
 Microservices, Accelerated Mobile Pages (AMP) Frameworks, and more to enhance your online presence
 and drive business growth."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg1}
                      serviceImage={restapi}
                      serviceIconBg={shapeIcon1}
                      Title="Rest API"
                      serviceDescription=" RESTful API is an application programming interface that enables efficient and secure communication
 with other software systems. At Buzz Global, we use RESTful API to integrate and connect various apps
 and services. Right from single web apps to Android and iOS, we develop effective RESTful APIs for
 enabling every client app to communicate with your cloud based backend app."
                    />
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <SingleServiceFour
                      titleClass="service__title service__title-color-4"
                      btnClass="service__link service__link-color-4"
                      // shapeImg={shapeImg2}
                      serviceImage={payment}
                      serviceIconBg={shapeIcon2}
                      Title="Payment Gateway Integration"
                      serviceDescription="Do you want to set up safe and secure payment gateways to handle payment options in your online
 store? Call Buzz Global now! Here, we offer payment gateway integration solutions to help retail
 businesses in providing 24x7 online support to their customers. Expand your services and integrate
 different payment gateway effectively with data protection and fraud safeguard mechanisms.
"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 mb-30">
              <Faq />
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </main>
  );
}

export default DevelopmentServices;

import React from 'react'
import Breadcrumb from '../../components/Breadcrumb';
import JobDetails from '../job/JobDetailsMain';
import About from '../home-2/AboutSection';


function CareerContent() {
  return (
    <main>
			<Breadcrumb
				pageTitle= "Career"
			/>
			<About />
			<JobDetails />

		</main>
  )
}

export default CareerContent
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';


const Faq = (props) => {
    const { itemClass } = props;
	return(
        <div className={itemClass ? itemClass : 'it-custom-accordion'}>
            <Accordion className="accordion" preExpanded={'a'}>
                <AccordionItem className="accordion-items" uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Website Design and Web Development are both the same?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p> No, they’re different.</p>
                        <ul className='faq-wrapper '>
                            <li><b>Web Designing</b>- Determines the look and feel of the website including layout, structure, content
 placement, and user interface elements like buttons, toggles, checkboxes, date pickers, radio
 buttons and more.</li>
 <li><b>Web Development</b>- Determines how a website should function, and looks after technical
 implementation like coding, and backend operations, including how it works behind the scenes.</li>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="b">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Which language is best for web development
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>The choice of language depends on your project requirements. So, for instance</p>
                        <ul className='faq-wrapper '>
                            <li>HTMLisgreatforstructuring web pages</li>
                            <li>Java works efficiently for enterprise-level applications</li>
                            <li>C++ is very well suited for big projects and building software</li>
                            <li>Kotlin works well for Android development and building server side application</li>
                            <li>.NET is perfect for developing web apps, desktop apps, mobile apps, and cloud-based apps</li>
                            <li>Swift is best for iOS apps</li>
                        </ul>
                        <p>Let's discuss first the platform and features you want in your website to choose the best language for
                        your web development.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="c">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Why does every business need a website?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>8 out of 10 customers are likely to engage with your business if it has a website. And more than 80% of
                        consumers are likely to visit your establishment within a day.</p>
                        <p>So, if you want your customers to access your store at any time of the day, from anywhere, and want to
                        know thembetter, it’s important to build a fast and responsive website.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="d">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Will I be able to update the site myself when it’s finished?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>If you want to build a website or make simple changes like image or text replacement in CMS, it is quite
 easy, simple, and straightforward. It doesn’t require technical know-how. However, in case of complex
 updates like design or layout changes or adding any advanced functionalities, it’s important to hire web
 development experts to save time and seek professional guidance.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="e">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span>Will you maintain my site for me?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Yes, absolutely. We offer ongoing support and maintenance services and help you make content
                        adjustments or add new feature updates to your site to keep your website up-to-date</p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div> 
	)
}

export default Faq
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const PaidMediaFaq = (props) => {
    const { itemClass } = props;
    return (
        <div className={itemClass ? itemClass : 'it-custom-accordion'}>
            <Accordion className="accordion" preExpanded={'a'}>
                <AccordionItem className="accordion-items" uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What is Paid Marketing?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Want to skip the wait for organic traffic and go straight to the spotlight? This is what PPC advertising means. It involves paying to promote a brand on powerful platforms like Google, LinkedIn, Instagram, Facebook, or Twitter. Typically, you only pay when someone clicks on your ad. This is comparatively a cost-effective approach to drive traffic, leads, and visibility.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="b">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Where to run PPC Ads?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>You can run PPC Ads on Google and Bing - the largest search engines, and on social media (such as Facebook, Twitter, Instagram, LinkedIn, YouTube) or any 3rd party websites.</p>
                        <p>At Buzz, our PPC specialists study your business and target audience to understand your industry landscape, considering your offerings to select the most valuable channels for your business. For instance, if you deal with B2B products, we will choose LinkedIn for rolling an ad campaign.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="c">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> What paid marketing strategies do you use?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>From search to social, we’ve got you covered! We use a mix of powerhouse paid marketing strategies including:</p>
                        <ul className='faq-wrapper'>
                            <li>Google & Bing Ads</li>
                            <li>Meta Ads</li>
                            <li>LinkedIn Ads</li>
                            <li>Amazon Ads</li>
                            <li>Display Ads</li>
                            <li>Shopping Ads</li>
                            <li>YouTube Ads</li>
                            <li>Video Marketing</li>
                            <li>Remarketing</li>
                        </ul>
                        <p>We research where your target audience sits and prepare content based on that to maximize the impact of ads.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="d">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Which metrics do you track to measure the success of paid advertising campaigns?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>At Buzz, we track key metrics such as:</p>
                        <ul className='faq-wrapper'>
                            <li>Impressions - Total number of views your content and advertisements get</li>
                            <li>Reach & Clicks – Number of unique users who’ve seen your ad</li>
                            <li>Website Traffic</li>
                            <li>Click-through rate (CTR) - Percentage of how many times your ad was clicked</li>
                            <li>Cost per click (CPC)</li>
                            <li>Conversion rate - Percentage of users who’ve completed a desired action</li>
                            <li>Return on ad spend (ROAS) - How much gross revenue a single campaign has generated</li>
                            <li>Customer Lifetime Value - Profit value a customer brings in throughout their relationship with the brand</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="e">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> How Long Does PPC Take to Work?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>It’s quick – but not instant! You may start seeing some results within hours or days of launching a campaign, but the actual results happen after 3 months as we fine-tune and optimize your campaigns over a few weeks.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="f">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Will I Get A Regular Progress Report?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Absolutely. We keep you in the loop continuously to deliver monthly metrics reports, optimization, and insight summaries related to your ad campaigns. From clicks to conversions, you’ll see exactly how your campaigns, ad groups, and keywords are performing for strategic decision-making.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="accordion-items" uuid="g">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            <span><i className="fal fa-check"></i></span> Can PPC work for local businesses?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Absolutely. Pay-per-click campaigns are highly effective for local targeting. You can target the most valuable customers by location or to people who are nearby and actively searching for what you offer.</p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default PaidMediaFaq;
